import {
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Box, Stack } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
//import asp_icon from "../../assets/image/ASP-ICON.png";
import asp_icon from "../../assets/image/bull_logo.svg";
import ReorderIcon from "@material-ui/icons/Reorder";
import background from "../../assets/image/background.png";
import backgroundLogin from "../../assets/image/background-header.png";
import backgroundHeader2 from "../../assets/image/background-header-2.png";
import EN from "../../assets/flag/uk-flag.png";
import JP from "../../assets/flag/jp-flag.png";
import KO from "../../assets/flag/ko-flag.png";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { PersonInfo } from "./PersonInfo";
import { AuthContext } from "containers/AuthProvider";
import ChatBadge from "containers/Admin/Chat/components/ChatBadge";
import { useFetch } from "api/api";
import { MessageContext } from "containers/MessageProvider";
import MenuNotification from "containers/Admin/Notification/MenuNotification";
import NotificationBadge from "containers/Admin/Notification/NotificationBadge";
import TwitterNotification from "./TwitterNotification";

interface Props {
  dataSidebar: any[];
}

export const HeaderMobile: React.FC<Props> = ({ dataSidebar }) => {
  const dataAuth = useContext(AuthContext);
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(
    i18n.language ? i18n.language : "en"
  );
  const displayLanguage = useMemo(() => {
    if (language === "en") return "English";
    if (language === "jp") return "日本語";
    if (language === "ko") return "한국어";
  }, [language]);

  const flag = useMemo(() => {
    if (language === "en") return EN;
    if (language === "jp") return JP;
    if (language === "ko") return KO;
  }, [language]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const changeLocation = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    setLanguage(value);
    handlePopoverClose();
    history.go(0);
  };

  const [anchorElNoti, setAnchorElNoti] = React.useState<null | HTMLElement>(
    null
  );
  const openNoti = Boolean(anchorElNoti);
  const handleClickNoti = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNoti(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
    revalidateNotificationNumber();
  };

  const { data: dataNewNotification, revalidate } = useFetch(
    dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? ["get", "/user/new-notification"]
      : null
  );
  const {
    data: dataNewNotificationNumber,
    revalidate: revalidateNotificationNumber,
  } = useFetch(
    dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? ["get", "/user/new-notification-number"]
      : null
  );
  const { updateDataNotiSns, updateNewNotiNumber } = useContext(MessageContext);
  useEffect(() => {
    updateDataNotiSns(dataNewNotification);
    updateNewNotiNumber(dataNewNotificationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNewNotification]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ width: "100%", height: "100%" }}
    >
      <img
        src={asp_icon}
        height={48}
        width={"auto"}
        style={{ cursor: "pointer" }}
        alt=""
        onClick={() => {
          history.push("/");
          (!dataAuth.admin?.country_id || !dataAuth.admin?.wallet_address) &&
            dataAuth.clear();
        }}
      />
      <Stack direction="row" alignItems="center">
        {/* <Box>
          {dataAuth.admin?.country_id && dataAuth.admin?.wallet_address ? (
            <div
              onClick={() => {
                history.push("/message");
              }}
              style={{ marginRight: "10px", cursor: "pointer" }}
            >
              <ChatBadge />
            </div>
          ) : null}
        </Box> */}

        {dataAuth.admin?.country_id && dataAuth.admin?.wallet_address ? (
          <div
            onClick={(e) => {
              handleClickNoti(e);
              revalidate();
            }}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <NotificationBadge
              open={openNoti}
              newNotification={dataNewNotificationNumber > 0 ? false : true}
            />
          </div>
        ) : null}
        <MenuNotification
          anchorEl={anchorElNoti}
          open={openNoti}
          handleClose={handleCloseNoti}
          revalidateNewNoti={revalidate}
          dataNewNoti={dataNewNotification}
        />

        <React.Fragment key={"left"}>
          <Button onClick={() => setOpenDrawer(true)}>
            <IconButton>
              <ReorderIcon style={{ color: "white", fontSize: "40px" }} />
            </IconButton>
          </Button>
          <Drawer
            classes={{ paper: classes.backgroundDrawer }}
            anchor={"left"}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
              style={{ height: "100%", padding: "10px 0px" }}
            >
              <div>
                {dataSidebar.map((value: any, index: number) => {
                  return (
                    value.display === true && (
                      <div
                        className={classes.containerDrawerItem}
                        onClick={() => {
                          history.push(value.path);
                          setOpenDrawer(false);
                        }}
                        key={index}
                      >
                        <p className={classes.drawerText}>{value.name}</p>
                      </div>
                    )
                  );
                })}
              </div>
              <div className={classes.informationContainer}>
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TwitterNotification />
                </div>
                <div
                  className={classes.iconFlag}
                  onClick={(e) => {
                    handlePopoverOpen(e);
                  }}
                >
                  <img
                    src={flag}
                    height={32}
                    width="auto"
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  <Typography style={{ color: "white", fontWeight: 800 }}>
                    {displayLanguage}
                  </Typography>
                  <ArrowDropDownIcon />
                </div>
                {dataAuth.admin ? (
                  <PersonInfo />
                ) : (
                  <div
                    style={{
                      borderRadius: theme.spacing(1),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: theme.spacing(2, 6),
                      height: theme.spacing(6),
                      background: "white",
                      border: "white solid 1px",
                      cursor: "pointer",
                      margin: "8px",
                    }}
                    onClick={() => history.push("/login")}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: 600,
                        color: "#333333",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("header.Login")}
                    </Typography>
                  </div>
                )}
              </div>
            </Stack>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => handlePopoverClose()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  padding: "10px",
                  width: "150px",
                  backgroundImage: `url(${backgroundLogin}), url(${background}), url(${backgroundHeader2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundBlendMode: "color-dodge",
                }}
                spacing={2}
              >
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  onClick={() => changeLocation("en")}
                >
                  <img src={EN} height={32} width="auto" alt="" />
                  <p className={classes.changeLanguageText}>English</p>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  onClick={() => changeLocation("jp")}
                >
                  <img src={JP} height={32} width="auto" alt="" />
                  <p className={classes.changeLanguageText}>日本語</p>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                  sx={{ width: "100%" }}
                  onClick={() => changeLocation("ko")}
                >
                  <img src={KO} height={32} width="auto" alt="" />
                  <p className={classes.changeLanguageText}>한국어</p>
                </Stack>
              </Stack>
            </Popover>
          </Drawer>
        </React.Fragment>
      </Stack>
    </Stack>
  );
};

const useStyles = makeStyles((theme) => ({
  backgroundDrawer: {
    backgroundColor: "#21243f80",
    minWidth: "240px",
  },
  containerDrawerItem: {
    width: "100%",
    height: "50px",
    paddingLeft: "10px",
  },
  drawerText: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 900,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  informationContainer: {
    borderTop: "1px solid #E7EBF0",
  },
  iconFlag: {
    margin: "10px",
    borderRadius: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    height: theme.spacing(6),
    backgroundImage: `url(${backgroundLogin}), url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "color-dodge",
    border: "white solid 1px",
  },
  changeLanguageText: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "white",
  },
}));
