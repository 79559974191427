import { Box, TextareaAutosize, Typography } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormProps } from "../WhiteListForm";
import { LabelForm } from "./Information";

export const CustomInfo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormProps>();
  const { fields } = useFieldArray<FormProps>({
    name: "questions",
  });
  const { t } = useTranslation();

  return (
    <div>
      {fields.map((item: any, index: number) => {
        return (
          <Box key={item.id} marginY="24px">
            <LabelForm>{item.question}</LabelForm>
            <TextareaAutosize
              {...register(`questions.${index}.answer`)}
              aria-label="empty textarea"
              placeholder={t("text_form.answer")}
              autoComplete="off"
              style={{
                maxWidth: "100%",
                width: "100%",
                height: "74px",
                padding: "14px",
                background: "#1F2039",
                color: "white",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                borderColor: errors.questions?.[index]?.answer
                  ? "#d32f2f"
                  : "#D9D5D5",
                borderRadius: "5px",
              }}
            />
            {errors.questions?.[index]?.answer && (
              <Typography sx={{ fontSize: "12px" }} color="#d32f2f" pl={2}>
                This field cannot be empty
              </Typography>
            )}
          </Box>
        );
      })}
    </div>
  );
};
