import {
  Box,
  styled,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormProps } from "../WhiteListForm";

export const Information = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormProps>();
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <LabelForm>{t("text_form.wallet_address")}</LabelForm>
        <CustomTextField
          {...register("wallet_address")}
          fullWidth
          size="small"
          error={!!errors.wallet_address}
          helperText={
            errors.wallet_address
              ? (errors.wallet_address.message as string)
              : " "
          }
        />
      </Box>
      <Box marginTop="24px">
        <LabelForm>{t("text_form.message")}</LabelForm>
        <TextareaAutosize
          {...register("message")}
          aria-label="empty textarea"
          placeholder={t("text_form.answer")}
          autoComplete="off"
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "74px",
            padding: "14px",
            background: "#1F2039",
            color: "white",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20px",
            borderColor: errors.message ? "#d32f2f" : "#D9D5D5",
            borderRadius: "5px",
          }}
        />
        {errors.message && (
          <Typography sx={{ fontSize: "12px" }} color="#d32f2f" pl={2}>
            This field cannot be empty
          </Typography>
        )}
      </Box>
    </>
  );
};

export const LabelForm = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "22px",
  color: "white",
  marginBottom: "20px",
}));

export const CustomTextField = styled(TextField)(() => ({
  width: "100%",

  "& .MuiInputBase-root": {
    fontSize: "16px",
    borderRadius: "5px",
    height: "56px",
    color: "white",
    fontWeight: 500,
    "& .MuiInputBase-input": {
      padding: "0 16px 0 16px",
      height: "100%",
      "&::placeholder": {
        color: "white",
        fontWeight: 600,
        fontSize: "16px",
      },
    },
    "& input::-webkit-outer-spin-button": {
      margin: 0,
      webkitAppearance: "none",
    },
    "& input::-webkit-inner-spin-button": {
      margin: 0,
      webkitAppearance: "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D9D5D5",
    },
    "&:hover fieldset": {
      borderColor: "#D9D5D5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D5D5",
    },
  },

  "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
    WebkitBoxShadow: "0 0 0 30px #1F2039 inset !important",
    WebkitTextFillColor: "white!important",
  },
}));
