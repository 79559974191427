import { Popover, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TelegramIcon from "../../assets/icons/telegram-icon.png";

interface Props {

}

const TwitterNotification: React.FC<Props> = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const {t} = useTranslation()
  return (
    <div style={{height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <img alt="telegram-icon" src={TelegramIcon} width="40px" height="40px" style={{marginRight: '20px', cursor: 'pointer'}} onClick={(e) => handleClick(e)} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 800 }}><a style={{color: 'black'}} href="https://t.me/bitbullsupport" rel="noopener noreferrer" target="_blank">{t("home.twitter_notion")}</a></Typography>
      </Popover>
    </div>
  )
}

export default TwitterNotification