import React from "react";
import { Stack } from "@mui/material";
import {
  Grid,
  GridSize,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
//import asp_icon from "../../assets/image/ASP-ICON.png";
import asp_icon from "../../assets/image/bull_logo.svg";
import world from "../../assets/icons/world-icon.png";
import mail from "../../assets/icons/mail-icon.png";
import telegram from "../../assets/icons/tele-icon.png";
import twitter from "../../assets/icons/twitter-icon-white.png";
import { useTranslation } from "react-i18next";

export default function FooterMobie() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const contact = [
    {
      icon: mail,
      iconWidth: 24,
      label: t("footer.Email"),
      value: "support@bitbull.online",
      xs: 12,
      md: 6,
      type: 'email'
    },
    {
      icon: telegram,
      iconWidth: 24,
      label: t("footer.Telegram"),
      value: "https://t.me/bitbullsupport",
      xs: 12,
      md: 7,
      type: 'link'
    },
    {
      icon: world,
      iconWidth: 24,
      label: t("footer.Website"),
      value: "http://bitbull.online/",
      xs: 12,
      md: 6,
      type: 'link'
    },
    {
      icon: twitter,
      iconWidth: 24,
      label: "Twitter",
      value: "https://twitter.com/BitBull_Global",
      xs: 12,
      md: 7,
      type: 'link'
    },
  ];
  return (
    <>
      <Stack className={classes.root}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: theme.spacing(2),
            }}
          >
            <img
              src={asp_icon}
              height={"auto"}
              width={72}
              style={{
                marginRight: theme.spacing(2.5),
              }}
              alt=""
            />
            <Typography
              style={{
                fontSize: "34px",
                lineHeight: "40px",
                fontWeight: 900,
                color: "white",
                fontFamily: "Aeonik",
              }}
            >
              BitBull
            </Typography>
          </Grid>
          <Grid container spacing={2} className="reveal-top">
            {contact.map((data, index: number) => {
              return (
                <Grid
                  item
                  xs={data.xs as GridSize}
                  md={data.md as GridSize}
                  key={index}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "14px",
                      }}
                    >
                      <img
                        src={data.icon}
                        height={"auto"}
                        width={data.iconWidth}
                        alt=""
                      />
                    </div>
                    <Typography className={classes.labelText}>
                      {data.label}
                    </Typography>
                  </div>
                  <a href={data.type == 'email' ? 'mailto:'+data.value : data.value} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'unset'}}>
                  <Typography className={classes.valueText}>
                    {data.value}
                  </Typography>
                  </a>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Stack>
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 900,
          lineHeight: "22px",
          color: "white",
          textAlign: "center",
          paddingBottom: "20px",
          paddingTop: "20px",
          background: "#00132C",
          fontFamily: "Lato",
        }}
      >
        {t("footer.back")}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#002154",
    padding: "32px 28px",
  },
  labelText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 500,
    color: "white",
    fontFamily: "Lato",
  },
  valueText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    color: "white",
    paddingLeft: "44px",
    fontFamily: "Lato",
  },
}));
