import { Box, TextareaAutosize, Typography } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormProps } from "../WhiteListForm";
import { LabelForm } from "./Information";
import QuestionMultiSelect from "./QuestionMultiSelect";
import QuestionRadio from "./QuestionRadio";

export const QuestionVer2 = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormProps>();
  const { fields } = useFieldArray<FormProps>({
    name: "questionsVer2",
  });
  const { t } = useTranslation();
  return (
    <div>
      {fields.map((item: any, index: number) => {
        return (
          <Box key={item.id} marginY="28px">
            <LabelForm
              sx={{
                marginBottom: item.type === 1 ? "20px" : "10px",
              }}
            >
              {item.question}
            </LabelForm>
            {item.type === 1 ? (
              <>
                <TextareaAutosize
                  {...register(`questionsVer2.${index}.answer_text`)}
                  aria-label="empty textarea"
                  placeholder={t("text_form.answer")}
                  autoComplete="off"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    height: "74px",
                    padding: "14px",
                    background: "#1F2039",
                    color: "white",
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    borderColor: errors.questionsVer2?.[index]?.answer_text
                      ? "#d32f2f"
                      : "#D9D5D5",
                    borderRadius: "5px",
                  }}
                />
                {errors.questionsVer2?.[index]?.answer_text && (
                  <Typography sx={{ fontSize: "12px" }} color="#d32f2f" pl={2}>
                    This field cannot be empty
                  </Typography>
                )}
              </>
            ) : item.type === 2 ? (
              <QuestionRadio index={index} data={item} />
            ) : (
              <QuestionMultiSelect index={index} data={item} />
            )}
          </Box>
        );
      })}
    </div>
  );
};
