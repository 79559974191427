import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormProps } from "../WhiteListForm";

export interface RadioProps {
  index: number;
  data: any;
  answerItem: any;
}

const CheckboxItem: React.FC<RadioProps> = ({ data, index, answerItem }) => {
  const { setValue, getValues, watch } = useFormContext<FormProps>();

  const question = watch("questionsVer2");

  return (
    <>
      <FormControlLabel
        sx={{
          color: "white",
        }}
        control={
          <Checkbox
            sx={{
              "& .MuiSvgIcon-root": {
                color: "#56CCF2",
              },
            }}
            checked={
              question?.[index]?.answer_selection.find(
                (el: any) => el.answer === answerItem.answer
              )?.selected
            }
            onChange={(e) => {
              const answerSelection = data.answer_selection;
              const oldValue = getValues("questionsVer2");
              oldValue.splice(index, 1, {
                question: data.question,
                type: data.type,
                answer_text: data.answer_text,
                answer_selection: answerSelection.map((itemAnswer: any) => {
                  const checked = e.target.checked;
                  const answer = itemAnswer.answer;
                  const selected =
                    e.target.name === answer ? checked : itemAnswer.selected;
                  return {
                    answer,
                    selected,
                  };
                }),
              });
              setValue("questionsVer2", [...oldValue]);
            }}
          />
        }
        name={answerItem.answer}
        label={answerItem.answer}
      />
    </>
  );
};

export default CheckboxItem;
