export const sidebardWidth = 300;

export const system_profit = 0.1

export var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const rewardStatus = {
  waiting: 1,
  claim: 2,
};

export const chainIdValue = {
  goerli: 5,
  mumbai: 80001,
  bsc: 56,
  sepolia: 11155111,
  ethereum: 1,
  bsc_testnet: 97,
};

export const contractIDOAddress = {
  [chainIdValue.goerli]: "0x02792511F5BEc43099028B3f5876Aa903074e449",
  [chainIdValue.mumbai]: "0x2b7b02a0EB53Be318B86bb4F5fd7457ABBBbAc51",
  [chainIdValue.sepolia]: "0x84c6c4d66a56f2F25b6ae2f284e167134eEdEe4F",
  [chainIdValue.bsc_testnet]: "0x6D90250E936c098bb191d64f9f7150ad7c475934",
  [chainIdValue.bsc]: "0x613231D244842b8dCACfc044e350B6b5A1A08274",
  [chainIdValue.ethereum]: "0xb1a8fe435c8127678717C8Ce2aF5c989e1F13a69",
};

export const contractSNSAddress = {
  [chainIdValue.goerli]: "0x4f76298A6dd955B9fD6E234Db163100d7b25e40E",
  [chainIdValue.mumbai]: "0xFeE2B43c7AcE58b2Ea0f954908A0e98beEE7Bc69",
  [chainIdValue.sepolia]: "0x38A5D58DC4Db074dae679B1c90ec9dfeA72947e7",
  [chainIdValue.bsc_testnet]: "0x581a87f732ACD6358E25fcb7044C1De217c1F373",
  [chainIdValue.bsc]: "0x23Ae5FaBfaA7a8c6B92Da3c8D20415Ad89ec0c41",
  [chainIdValue.ethereum]: "0xA7e5dbD0Cd21493a873fb73Cfa41D56FFe64172E",
};

export const Network = [
  {
    networkName: "Ethereum Test",
    newRPCURL: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    ChainID: 3,
    Symbol: "ETH",
    Explorer: "https://ropsten.etherscan.io",
    name: "Ethereum",
  },
  {
    networkName: "Polygon",
    newRPCURL: "https://rpc-mainnet.matic.network",
    ChainID: 137,
    Symbol: "MATIC",
    Explorer: "https://polygonscan.com",
    name: "Polygon",
  },
  {
    networkName: "Mumbai Testnet",
    newRPCURL: "https://rpc-mumbai.maticvigil.com",
    ChainID: 80001,
    Symbol: "MATIC",
    Explorer: "https://mumbai.polygonscan.com/",
    name: "Mumbai Testnet",
  },
  {
    networkName: "Binance Smart Chain",
    newRPCURL: "https://bsc-dataseed.binance.org/",
    ChainID: 56,
    Symbol: "BNB",
    Explorer: "https://bscscan.com/",
    name: "Bnc",
  },
];

export const connectors = [
  {
    title: "MetaMask",
    id: "injected",
  },
  {
    title: "Wallet Connect",
    id: "walletconnect",
  },
];

export const IS_ENABLE_CLAIM = {
  YES: 2,
  NO: 1,
};

export const IS_CLAIM = {
  YES: 1,
  NO: 0
}

export const IS_TOKENSALE_ENABLE = {
  YES: 1,
  NO: 0,
};

export const IS_WHITELIST_ENABLE = {
  YES: 1,
  NO: 0,
};

export const IS_SNS_ENABLE = {
  YES: 1,
  NO: 0,
};

export const IS_TOKENSALE_ENABLE_FLAG = {
  YES: 1,
  NO: 0,
};

export const IS_WHITELIST_ENABLE_FLAG = {
  YES: 1,
  NO: 0,
};

export const IS_SNS_ENABLE_FLAG = {
  YES: 1,
  NO: 0,
};

export const IS_EXIT = {
  YES: 2,
  NO: 1,
};

export const TOKEN_SALE_STATUS = {
  LAUNCHED: 1,
  UPCOMING: 2,
  ONGOING: 3,
};

export const SNS_STATUS = {
  LAUNCHED: 1,
  UPCOMING: 2,
  ONGOING: 3,
}

export const WORK_STATUS = {
  DO: 1,
  WAIT_CONFIRM: 2,
  DONE: 3,
  REWORK: 4,
};

export const APPROVAL_STATUS = {
  WAIT: 1,
  APPROVE: 2,
  REJECT: 3,
  DEPOSIT: 4,
  SUBMITTED: 5,
  DONE: 6,
  LEAVE: 7,
  REWORK: 8,
};

export const IS_PUBLIC = {
  YES: 1,
  NO: 0,
};

export const NOTIFICATION_TYPE = {
  PROJECT: 1,
  PROPOSAL: 2,
  WORK: 3,
  REQUEST: 4,
  FAQ: 5,
};

export const SNS_PROMOTION_REPORT_TYPE = {
  WORK_DONE: 1,
  COMMISSION: 2
}
