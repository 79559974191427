import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FormProps } from "../WhiteListForm";

export interface RadioProps {
  index: number;
  data: any;
}

const QuestionRadio: React.FC<RadioProps> = ({ data, index }) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext<FormProps>();

  const question = watch("questionsVer2");
  return (
    <>
      <FormControl>
        <RadioGroup
          sx={{
            "& .MuiSvgIcon-root": {
              color: "#56CCF2",
            },
          }}
          {...register(`questionsVer2.${index}.answer_text`)}
          value={
            question?.[index]?.answer_selection.find((el: any) => el.selected)
              ?.answer
          }
          onChange={(e) => {
            const answerSelection = data.answer_selection;
            const oldValue = getValues("questionsVer2");
            oldValue.splice(index, 1, {
              question: data.question,
              type: data.type,
              answer_text: e.target.value,
              answer_selection: answerSelection.map((itemAnswer: any) => ({
                answer: itemAnswer.answer,
                selected: itemAnswer.answer === e.target.value,
              })),
            });

            setValue("questionsVer2", [...oldValue]);
          }}
        >
          {data.answer_selection.map((answerItem: any, idx: number) => (
            <FormControlLabel
              key={idx}
              sx={{
                color: "white",
              }}
              value={answerItem.answer}
              control={<Radio />}
              label={answerItem.answer}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {errors.questionsVer2?.[index]?.answer_text &&
        question?.[index]?.answer_selection.find((el: any) => el.selected)
          ?.answer === undefined && (
          <Typography sx={{ fontSize: "12px" }} color="#d32f2f" pl={2}>
            This field cannot be empty
          </Typography>
        )}
    </>
  );
};

export default QuestionRadio;
