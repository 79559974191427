import { lazy } from "react";

const Index = lazy(() => import("../containers/Admin/Home/Home"));
// const AboutUs = lazy(() => import("../containers/Admin/AboutUs/AboutUs"));
const BecomeSponsor = lazy(
  () => import("../containers/Admin/BecomeSponsor/BecomeSponsor")
);
const NotiRegister = lazy(
  () => import("../containers/Admin/NotiRegister/NotiRegister")
);

const Chat = lazy(() => import("../containers/Admin/Chat/Chat"));
const Project = lazy(() => import("../containers/Admin/Project/Project"));
const ProjectDetail = lazy(
  () => import("../containers/Admin/ProjectDetail/ProjectDetail")
);
const MyPerformance = lazy(
  () => import("../containers/Admin/MyPerformance/MyPerformance")
);

const IDO = lazy(() => import("../containers/Admin/IDO/IDO"));
const IDO1 = lazy(() => import("../containers/Admin/IDO/IDO1"));

// const WhiteListForm = lazy(
//   () => import("../containers/Admin/WhiteListForm/WhiteListForm")
// );

export const adminPrefix = "";
export const adminApi = "/admin";

export function AdminRoutes() {
  const adminRoutes = [
    {
      path: "/",
      component: Index,
      protected: true,
      perm: "",
      name: "Dashbroad",
    },
    // {
    //   path: "/aboutUs",
    //   component: AboutUs,
    //   protected: true,
    //   perm: "",
    //   name: "About Us",
    // },
    {
      path: "/becomeSponsor",
      component: BecomeSponsor,
      protected: true,
      perm: "",
      name: "Become Sponsor",
    },
    {
      path: "/notificationRegister",
      component: NotiRegister,
      protected: true,
      perm: "",
      name: "Notification Register",
    },
    {
      path: "/message",
      component: Chat,
      protected: true,
      perm: "admin",
      name: "Message",
    },
    {
      path: "/project",
      component: Project,
      protected: true,
      perm: "admin",
      name: "Project",
    },
    {
      path: "/project-tokensale/:id",
      component: ProjectDetail,
      protected: true,
      perm: "admin",
      name: "Project detail",
    },
    {
      path: "/project-snspromotion/:id",
      component: ProjectDetail,
      protected: true,
      perm: "",
      name: "Project detail",
    },
    {
      path: "/project-whitelist/:id",
      component: ProjectDetail,
      protected: true,
      perm: "admin",
      name: "Project detail",
    },
    {
      path: "/myPerformance",
      component: MyPerformance,
      protected: true,
      perm: "admin",
      name: "Project",
    },
    {
      path: "/ido",
      component: IDO,
      protected: true,
      perm: "",
      name: "IDO",
    },
    {
      path: "/ido-1",
      component: IDO1,
      protected: true,
      perm: "",
      name: "IDO",
    },
    // {
    //   path: "/whiteListForm",
    //   component: WhiteListForm,
    //   protected: true,
    //   perm: "",
    //   name: "White list form",
    // },
  ];

  return { adminRoutes };
}
