import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import asp_icon from "../../assets/image/bull_logo.svg";
import { makeStyles, Popover } from "@material-ui/core";
import { AppBar, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import background from "../../assets/image/background.png";
import backgroundLogin from "../../assets/image/background-header.png";
import backgroundHeader2 from "../../assets/image/background-header-2.png";
import { useHistory } from "react-router-dom";
import { AuthContext } from "containers/AuthProvider";
import { useTranslation } from "react-i18next";
import EN from "../../assets/flag/uk-flag.png";
import JP from "../../assets/flag/jp-flag.png";
import KO from "../../assets/flag/ko-flag.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { PersonInfo } from "./PersonInfo";
import { HeaderMobile } from "./HeaderMobile";
import { isMobile } from "common/constants";
import NotificationBadge from "containers/Admin/Notification/NotificationBadge";
import MenuNotification from "containers/Admin/Notification/MenuNotification";
import { useFetch } from "api/api";
import { MessageContext } from "containers/MessageProvider";
import TwitterNotification from "./TwitterNotification";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    height: "100px",
    padding: "0px",
  },
  showHeader: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  headerMobie: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  linkTextActive: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
    color: "#FFFFFF",
  },
  linkTextUnActive: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#FFFFFF",
  },
  activeLink: {
    backgroundImage: `linear-gradient(to bottom,#ffffff00,#003469)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "color-dodge",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
    cursor: "pointer",
    minWidth: "120px",
  },
  noActiveLink: {
    background: "transparent",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
    cursor: "pointer",
    minWidth: "120px",
  },
  changeLanguageText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    color: "white",
    fontFamily: "Montserrat",
  },
  backgroundDrawer: {
    backgroundColor: "#21243f80",
    minWidth: "240px",
  },
  containerDrawerItem: {
    width: "100%",
    height: "50px",
    paddingLeft: "10px",
  },
  drawerText: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 900,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
}));

export default function Header(props: any) {
  const { t, i18n } = useTranslation();
  const dataAuth = useContext(AuthContext);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.screen.width > 1000) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const [activeSideBar, setActiveSideBar] = useState<string>("");

  const data = window.location.pathname.split("/");

  useEffect(() => {
    if (data.length > 1) {
      setActiveSideBar(data[1]);
      localStorage.setItem("url", window.location.pathname);
    }
  }, [data, history]);

  const dataSidebar = useMemo(() => {
    return !dataAuth.admin
      ? [
          {
            name: t("header.what_is_bitbull"),
            path: "/#whatisbitbull",
            active: "",
            display: true,
          },
          {
            name: t("header.become_affiliator"),
            path: "/login",
            active: "becomeAffiliator",
            display: dataAuth.admin ? false : true,
          },
          {
            name: t("header.become_sponsor"),
            path: "/becomeSponsor",
            active: "becomeSponsor",
            display: dataAuth.admin ? false : true,
          },
          {
            name: t("header.Project"),
            path: "/project",
            active: "project",
            display: dataAuth.admin ? true : false,
          },
          {
            name: t("header.My_performance"),
            path: "/myPerformance",
            active: "myPerformance",
            display: dataAuth.admin ? true : false,
          },
        ]
      : dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? [
          {
            name: t("header.what_is_bitbull"),
            path: "/#whatisbitbull",
            active: "",
            display: true,
          },
          {
            name: t("header.become_affiliator"),
            path: "/login",
            active: "becomeAffiliator",
            display: dataAuth.admin ? false : true,
          },
          {
            name: t("header.become_sponsor"),
            path: "/becomeSponsor",
            active: "becomeSponsor",
            display: dataAuth.admin ? false : true,
          },
          {
            name: t("header.Project"),
            path: "/project",
            active: "project",
            display: dataAuth.admin ? true : false,
          },
          {
            name: t("header.My_performance"),
            path: "/myPerformance",
            active: "myPerformance",
            display: dataAuth.admin ? true : false,
          },
        ]
      : [
          {
            name: t("header.Update_Information"),
            path: "/",
            active: "",
            display: true,
          },
        ];
  }, [dataAuth, t]);

  const [language, setLanguage] = useState<string>(
    i18n.language ? i18n.language : "en"
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const flag = useMemo(() => {
    if (language === "en") return "EN";
    if (language === "jp") return "JP";
    if (language === "ko") return "KO";
  }, [language]);

  const changeLocation = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    setLanguage(value);
    handlePopoverClose();
    history.go(0);
  };

  const [anchorElNoti, setAnchorElNoti] = React.useState<null | HTMLElement>(
    null
  );
  const openNoti = Boolean(anchorElNoti);
  const handleClickNoti = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNoti(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setAnchorElNoti(null);
    revalidateNotificationNumber();
  };

  const { data: dataNewNotification, revalidate } = useFetch(
    dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? ["get", "/user/new-notification"]
      : null
  );
  const {
    data: dataNewNotificationNumber,
    revalidate: revalidateNotificationNumber,
  } = useFetch(
    dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? ["get", "/user/new-notification-number"]
      : null
  );
  const { updateDataNotiSns, updateNewNotiNumber } = useContext(MessageContext);
  useEffect(() => {
    updateDataNotiSns(dataNewNotification);
    updateNewNotiNumber(dataNewNotificationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNewNotification, dataNewNotificationNumber]);

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
        sx={{
          backgroundColor: "rgba(143, 207, 177, 0.1)",
          fontFamily: "Montserrat",
        }}
        id="header"
      >
        <Toolbar className={classes.toolbar}>
          {show === true && (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                padding: "0px 140px",
              }}
              className={classes.showHeader}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <img
                  src={asp_icon}
                  height={72}
                  width={"auto"}
                  style={{ marginRight: theme.spacing(8), cursor: "pointer" }}
                  alt=""
                  onClick={() => {
                    history.push("/");
                    (!dataAuth.admin?.country_id ||
                      !dataAuth.admin?.wallet_address) &&
                      dataAuth.clear();
                  }}
                />
                {dataSidebar.map((value, index) => {
                  return (
                    value.display === true && (
                      <div
                        className={
                          activeSideBar === value.active
                            ? classes.activeLink
                            : classes.noActiveLink
                        }
                        onClick={() => history.push(value.path)}
                        key={index}
                      >
                        <p
                          className={
                            activeSideBar === value.active
                              ? classes.linkTextActive
                              : classes.linkTextUnActive
                          }
                        >
                          {value.name}
                        </p>
                      </div>
                    )
                  );
                })}
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <TwitterNotification />

                {dataAuth.admin?.country_id &&
                dataAuth.admin?.wallet_address ? (
                  <div
                    onClick={(e) => {
                      handleClickNoti(e);
                      revalidate();
                    }}
                    style={{ marginRight: "10px", cursor: "pointer" }}
                  >
                    <NotificationBadge
                      open={openNoti}
                      newNotification={
                        dataNewNotificationNumber > 0 ? false : true
                      }
                    />
                  </div>
                ) : null}

                <MenuNotification
                  anchorEl={anchorElNoti}
                  open={openNoti}
                  handleClose={handleCloseNoti}
                  revalidateNewNoti={revalidate}
                  dataNewNoti={dataNewNotification}
                />
                <div
                  style={{
                    background: "linear-gradient(to right, #2C5AFF, #1FE7E4)",
                    marginRight: "10px",
                    padding: "1px",
                    borderRadius: "25px",
                    cursor: 'pointer'
                  }}
                >
                  <div
                    style={{
                      borderRadius: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: theme.spacing(2),
                      height: theme.spacing(5),
                      background: `#2f4963`,
                      backdropFilter: "blur(4px)",
                    }}
                    onClick={(e) => {
                      handlePopoverOpen(e);
                    }}
                  >
                    {flag}
                    <ArrowDropDownIcon />
                  </div>
                </div>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => handlePopoverClose()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                      padding: "10px",
                      width: "150px",
                      backgroundImage: `url(${backgroundLogin}), url(${background}), url(${backgroundHeader2})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundBlendMode: "color-dodge",
                    }}
                    spacing={2}
                  >
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ width: "100%" }}
                      onClick={() => changeLocation("en")}
                    >
                      <img src={EN} height={32} width="auto" alt="" />
                      <p className={classes.changeLanguageText}>English</p>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ width: "100%" }}
                      onClick={() => changeLocation("jp")}
                    >
                      <img src={JP} height={32} width="auto" alt="" />
                      <p className={classes.changeLanguageText}>日本語</p>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{ width: "100%" }}
                      onClick={() => changeLocation("ko")}
                    >
                      <img src={KO} height={32} width="auto" alt="" />
                      <p className={classes.changeLanguageText}>한국어</p>
                    </Stack>
                  </Stack>
                </Popover>
                {dataAuth.admin ? (
                  <PersonInfo />
                ) : (
                  <div
                    style={{
                      background: "linear-gradient(to right, #2C5AFF, #1FE7E4)",
                      padding: "1px",
                      borderRadius: "25px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: theme.spacing(2, 3),
                        height: theme.spacing(5),
                        background: "#2f4963",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/login")}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontWeight: 500,
                          color: "#ffffff",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {t("header.Login")}
                      </Typography>
                    </div>
                  </div>
                )}
              </Stack>
            </Stack>
          )}
          {isMobile.any() && window.innerWidth < 1440 && (
            <div className={classes.headerMobie}>
              <HeaderMobile dataSidebar={dataSidebar} />
            </div>
          )}
        </Toolbar>
        <div
          style={{
            height: "1px",
            width: "100%",
            background: "linear-gradient(to right, #2C5AFF, #1FE7E4)",
          }}
        ></div>
      </AppBar>
    </div>
  );
}
