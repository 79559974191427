import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

/* eslint-disable no-undef */
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
let fireApp: firebase.app.App;
const firebaseConfig = {
  apiKey: "AIzaSyBppHjDnnxeN6bNpJNME4w0suikNhG30YU",
  authDomain: "asp-project-10bc9.firebaseapp.com",
  projectId: "asp-project-10bc9",
  storageBucket: "asp-project-10bc9.appspot.com",
  messagingSenderId: "211765898947",
  appId: "1:211765898947:web:d5724081a0586453767c76",
  measurementId: "G-EF53CN8GDT",
};

fireApp = firebase.initializeApp(firebaseConfig);

// các tham số này là phần config lấy ra được từ phần 2. setting firebase nhé

export default fireApp;
