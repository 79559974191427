import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";

interface Props {
  sns: SNSType[];
  handleChangeSns: (value: SNSType[]) => void;
  checkSns: boolean;
  handleChangeCheckSns: (value: boolean) => void;
  clickButton: boolean;
  handleChange: (value: boolean) => void;
}

type SNSType = {
  sns_name: string;
  url: string;
  error?: boolean;
};

export const SNSForm: React.FC<Props> = ({
  sns,
  handleChangeSns,
  checkSns,
  handleChangeCheckSns,
  clickButton,
  handleChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const tempSNS: SNSType = {
    sns_name: "",
    url: "",
  };
  const addSNS = () => {
    let temp = [...sns];
    temp.push(tempSNS);
    handleChangeSns(temp);
  };
  const changeValue = (value: string, index: number) => {
    let temp = [...sns];
    temp[index].url = value;
    handleChangeSns(temp);
    handleChange(false);
  };
  const changeName = (value: string, index: number) => {
    let temp = [...sns];
    temp[index].error = sns.some((data: SNSType) => data.sns_name === value);
    if (sns.some((data: SNSType) => data.sns_name === value) === true) {
      handleChangeCheckSns(true);
    } else {
      handleChangeCheckSns(false);
    }
    temp[index].sns_name = value;
    handleChangeSns(temp);
    handleChange(false);
  };
  const deleteSns = (index: number) => {
    let temp = [...sns];
    temp.splice(index, 1);
    handleChangeSns(temp);
  };

  useEffect(() => {}, [sns]);
  return (
    <Stack>
      {sns.map((value: SNSType, index: number) => {
        return (
          <Grid container spacing={2} key={index}>
            <Grid item xs={5}>
              <Typography className={classes.labelText}>
                {t("Update_info.name")}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                error={value.error}
                className={classes.textFieldContainer}
                value={value.sns_name}
                onChange={(e) => changeName(e.target.value as string, index)}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px" },
                }}
              />
            </Grid>
            <Grid item xs={7} style={{ position: "relative" }}>
              <Typography className={classes.labelText}>
                {t("Update_info.value")}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                value={value.url}
                onChange={(e) => changeValue(e.target.value as string, index)}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px" },
                }}
              />
              <Stack
                style={{
                  position: "absolute",
                  right: "-40px",
                  marginTop: "-50px",
                }}
                onClick={() => deleteSns(index)}
              >
                <IconButton color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </Grid>
            {value.error === true && (
              <Typography
                style={{
                  color: "#f44336",
                  fontSize: "14px",
                  fontWeight: 400,
                  marginLeft: "10px",
                }}
              >
                {t("Update_info.duplicate")}
              </Typography>
            )}
            {value.error !== true &&
              clickButton === true &&
              (value.sns_name === "" || value.url === "") && (
                <Typography
                  style={{
                    color: "#f44336",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginLeft: "10px",
                  }}
                >
                  {t("home.Not_empty")}
                </Typography>
              )}
          </Grid>
        );
      })}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{
          marginTop: "20px",
          background: "rgba(255, 255, 255, 0.1)",
          border: "1px solid #bdbdbd4d",
          padding: 0,
          height: "50px",
          borderRadius: "12px",
        }}
        onClick={() => addSNS()}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontWeight: 700,
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
          }}
        >
          +
        </Typography>
      </Button>
      <div
        style={{
          width: "100%",
          borderBottom: "#f3f3f3 solid 1px",
          marginTop: "24px",
        }}
      ></div>
    </Stack>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    background: "tranparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "white",
    },
  },
  lineColor: {
    color: "white",
  },
  labelText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "white",
    marginBottom: "2px",
    marginTop: "10px",
  },
  textFieldContainer: {
    backgroundColor: "#0000004d",
    borderRadius: "8px",
    border: "1px solid #bdbdbd4d",
    color: "white",
  },
}));
