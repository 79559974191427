import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

interface Props {
  files: any;
  setFiles: any;
  error: string;
  readOnly: boolean;
  name: any;
}

export const UploadFile: React.FC<Props> = ({
  files,
  setFiles,
  error,
  readOnly,
  name,
}) => {
  const [fileUpload, setFileUpload] = useState<any[]>([]);
  useEffect(() => {
    if (typeof files == "string") {
      setFileUpload([
        {
          name: "",
          preview: files,
        },
      ]);
    } else {
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (!readOnly) {
        setFileUpload(
          acceptedFiles.map((file) =>
            Object.assign(
              file,
              {
                preview: URL.createObjectURL(file),
              },
              setFiles(file)
            )
          )
        );
      }
    },
    accept: ["image/*"],
    disabled: readOnly,
  });

  const images = fileUpload.map((value) => {
    const fileScan = value?.preview.split(".");

    return (
      <div key={value.name}>
        <div
          style={{
            textAlign: "center",
            border: "1px solid white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            borderStyle: "dotted",
          }}
        >
          {value?.type == "application/pdf" ||
          fileScan[fileScan.length - 1] == "pdf" ? (
            <div title={name}>
              <div
                style={{
                  width: 230,
                  overflow: "hidden",
                  height: "30px",
                  lineHeight: "30px",
                }}
              >
                {name}
              </div>
            </div>
          ) : (
            <img
              src={value.preview}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "200px",
                maxHeight: "200px",
                objectFit: "contain",
                borderRadius: "50%",
              }}
              alt={"preview"}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div
      {...getRootProps()}
      style={{ margin: "auto", marginBottom: 15, cursor: "pointer" }}
    >
      <input readOnly={readOnly} {...getInputProps()} multiple={false} />
      {files ? (
        <div>
          <div>{images}</div>
        </div>
      ) : (
        <div>
          <div
            style={
              error
                ? {
                    minHeight: "200px",
                    width: "100%",
                    height: "100%",
                    border: "red solid 2px",
                    borderRadius: 5,
                    borderStyle: "dotted",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "red",
                  }
                : {
                    minHeight: "200px",
                    width: "100%",
                    height: "100%",
                    border: "#c4c4c4 solid 2px",
                    borderRadius: 5,
                    borderStyle: "dotted",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }
            }
          >
            <div>
              <div style={{ width: "100%" }}>
                <CloudUploadIcon fontSize={"large"} />
              </div>
              {error && <span style={{ width: "100%" }}>{error}</span>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
