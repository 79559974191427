import {
  Avatar,
  Box,
  Stack,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import React from "react";
import defaultAvatar from "assets/Avatar/avatar1.png";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
interface Props {
  url?: string;
  name: string;
  SizeText?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
}

const UserInfo: React.FC<Props> = ({ url, name, SizeText }) => {
  return (
    <Box>
      <Stack direction={"row"} alignItems="center">
        {url ? (
          <img
            src={url}
            height="40px"
            width="40px"
            alt=""
            style={{
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        ) : (
          <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
        )}

        <Typography
          variant={SizeText || "body1"}
          sx={{
            marginLeft: "10px",
            color: "white",
            fontWeight: 700,
            overflow: "hidden",
            WebkitLineClamp: "1",
            //display: "-webkit-box",
            //WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default UserInfo;
