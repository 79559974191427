import { Chain } from "wagmi";

const EthereumMainnet: Chain = {
  id: 1,
  name: "Ethereum Mainnet",
  network: "ethereum",
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://mainnet.infura.io/v3/f4f7cda0322242ca832f10069ee48f0d",
  },
  blockExplorers: {
    default: { name: "Etherscan", url: "https://etherscan.io/" },
  },
  testnet: false,
};

const BinanceMainChain: Chain = {
  id: 56,
  name: "BNB Smart Chain Mainnet",
  network: "binance",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://bsc-dataseed1.binance.org:443",
  },
  blockExplorers: {
    default: { name: "BinanceScan", url: "https://bscscan.com/" },
  },
  testnet: false,
};

const GoerliTestnetChain: Chain = {
  id: 5,
  name: "Goerli",
  network: "goerli",
  nativeCurrency: {
    name: "GoerliETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://goerli.infura.io/v3/eb7c6a1e2a1846a6850a27c2c933a190",
  },
  blockExplorers: {
    default: { name: "Goerli", url: "https://goerli.etherscan.io" },
  },
  testnet: true,
};

const PolygonMumbaiChain: Chain = {
  id: 80001,
  name: "Mumbai Testnet",
  network: "binance",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: {
    default: "https://rpc.ankr.com/polygon_mumbai",
  },
  blockExplorers: {
    default: { name: "MumbaiScan", url: "https://mumbai.polygonscan.com/" },
  },
  testnet: true,
};

const EthMainnet = () => EthereumMainnet;
const BinanceChain = () => BinanceMainChain;
const GoerliChain = () => GoerliTestnetChain;
const MumbaiChain = () => PolygonMumbaiChain;

const CHAIN_SETTINGS = () => [
  EthMainnet(),
  BinanceChain(),
  MumbaiChain(),
  GoerliChain(),
];

export { BinanceChain, CHAIN_SETTINGS };
