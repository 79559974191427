import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Typography } from "@mui/material";
//import logo from "../../assets/image/ASP-ICON.png";
import logo from "../../assets/image/LogoBitBullText.png";
import background from "../../assets/image/background.png";
import backgroundAll from "../../assets/image/background-main.png";
import backgroundLogin from "../../assets/image/background-tab-log.png";
import facebook from "../../assets/icons/facebook-icon.png";
import twitter from "../../assets/icons/twitter-icon.png";
import google from "../../assets/icons/google-icon.png";
import firebase from "firebase/compat/app";
import "@firebase/auth";
import { useAPI } from "api/api";
import { AuthContext } from "containers/AuthProvider";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    background: "#efebe9",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundAll})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    backgroundSize: "cover",
    alignContent: "center",
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 270px)",
    },
    [theme.breakpoints.down("lg")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      padding: "0px calc(5% - 420px/2 + 200px)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "100%",
      padding: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  contentRight: {
    padding: "60px 58px",
    width: 620,
    background:
      "linear-gradient(180deg, rgba(29, 79, 255, 0.2) 0%, rgba(29, 79, 255, 0) 100%)",
    border: "1px solid",
    borderRadius: "16px",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(0deg, rgba(44, 90, 255, 0) 0.83%, #1FE7E4 50.76%, rgba(41, 119, 250, 0) 100%)",
  },
  imageIcon: {
    width: 30,
    maxHeight: 30,
    marginRight: "16px",
  },
  textButton: {
    fontSize: "16px",
    fontWeight: 800,
    color: "white",
    minWidth: "120px",
  },
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "white",
    },
  },
  lineColor: {
    color: "white",
  },
}));

export default function Login() {
  const classes = useStyle();
  const api = useAPI();
  const dataAuth = useContext(AuthContext);
  const history = useHistory();
  // useEffect(() => {
  //   history.push("/");
  // }, [history]);

  const LoginGoogle = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(async (result: any) => {
        console.log(result);
        const data = await api.fetcher("post", "/user/login-social-media", {
          method: 1,
          social_account_id: result?.additionalUserInfo?.profile?.id,
          avatar_url: result?.additionalUserInfo?.profile?.picture,
          google_id: result?.additionalUserInfo?.profile?.id,
          name: result?.additionalUserInfo?.profile?.name,
          email: result?.user?.email,
        });
        console.log(result?.user?.email);
        dataAuth.updateAdminToken(data?.access_token, true);
        dataAuth.updateAdmin(data?.user);
        let search = window.location.search.split("=");
        if (data?.user?.wallet_address && data?.user?.country_id) {
          if (search[0] == "?url") {
            window.location.href = decodeURIComponent(search[1]);
          } else {
            history.push("/");
          }
        } else {
          history.push("/?url=" + search[1]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoginFaceBook = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(async (result: any) => {
        console.log(result);
        const data = await api.fetcher("post", "/user/login-social-media", {
          method: 2,
          social_account_id: result?.additionalUserInfo?.profile?.id,
          avatar_url: result?.additionalUserInfo?.profile?.picture?.data?.url,
          facebook_id: result?.additionalUserInfo?.profile?.id,
          name: result?.additionalUserInfo?.profile?.name,
          email: result?.additionalUserInfo?.profile?.email,
        });
        dataAuth.updateAdminToken(data?.access_token, true);
        dataAuth.updateAdmin(data?.user);
        let search = window.location.search.split("=");
        if (data?.user?.wallet_address && data?.user?.country_id) {
          if (search[0] == "?url") {
            window.location.href = decodeURIComponent(search[1]);
          } else {
            history.push("/");
          }
        } else {
          history.push("/?url=" + search[1]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LoginTwitter = () => {
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.TwitterAuthProvider())
      .then(async (result: any) => {
        console.log(result);
        const data = await api.fetcher("post", "/user/login-social-media", {
          method: 4,
          social_account_id: result?.additionalUserInfo?.profile?.id,
          avatar_url: result?.additionalUserInfo?.profile?.profile_image_url,
          twitter_id: result?.additionalUserInfo?.profile?.id,
          twitter_screen_name: result?.additionalUserInfo?.profile?.screen_name,
          name: result?.additionalUserInfo?.profile?.name,
        });
        dataAuth.updateAdminToken(data?.access_token, true);
        dataAuth.updateAdmin(data?.user);
        let search = window.location.search.split("=");
        if (data?.user?.wallet_address && data?.user?.country_id) {
          if (search[0] == "?url") {
            window.location.href = decodeURIComponent(search[1]);
          } else {
            history.push("/");
          }
        } else {
          history.push("/?url=" + search[1]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{
        padding: 0,
        maxWidth: "100vw",
        height: "100%",
        background: "#efebe9",
      }}
    >
      <div style={{ height: "100%", background: "#efebe9" }}>
        <div className={classes.container}>
          <Card className={classes.content}>
            <div className={classes.contentRight}>
              <div
                style={{
                  marginBottom: 15,
                  textAlign: "center",
                }}
              >
                <img style={{ width: 255, maxHeight: 82 }} src={logo} alt="" />
              </div>
              <Typography
                style={{
                  marginTop: "32px",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "white",
                  fontFamily: "Lato",
                }}
              >
                Continue with
              </Typography>
              <Button
                style={{
                  height: "50px",
                  marginTop: "24px",
                  borderRadius: "12px",
                  border: "1px solid #56CCF2",
                  backgroundColor: "#ffffff1a",
                }}
                fullWidth
                variant="contained"
                onClick={() => LoginFaceBook()}
              >
                <img className={classes.imageIcon} src={facebook} alt="" />
                <Typography className={classes.textButton}>
                  Facebook ID
                </Typography>
              </Button>

              <Button
                style={{
                  height: "50px",
                  marginTop: "18px",
                  borderRadius: "12px",
                  border: "1px solid #56CCF2",
                  backgroundColor: "#ffffff1a",
                }}
                fullWidth
                variant="contained"
                onClick={() => LoginGoogle()}
              >
                <img className={classes.imageIcon} src={google} alt="" />
                <Typography className={classes.textButton}>
                  google ID
                </Typography>
              </Button>

              <Button
                style={{
                  height: "50px",
                  marginTop: "18px",
                  borderRadius: "12px",
                  border: "1px solid #56CCF2",
                  backgroundColor: "#ffffff1a",
                }}
                fullWidth
                variant="contained"
                onClick={() => LoginTwitter()}
              >
                <img className={classes.imageIcon} src={twitter} alt="" />
                <Typography className={classes.textButton}>
                  Twitter ID
                </Typography>
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
