import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useAPI } from "api/api";
import { Proposal } from "common/Type/proposalType";
import { ResponseProject } from "common/Type/responseProject";
import { AuthContext } from "containers/AuthProvider";
import React, { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormProposal from "./FormProposal";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
interface DialogProps {
  open: boolean;
  dataProject: ResponseProject;
  handleClose: () => void;
  revalidate: () => void;
  edit?: boolean;
}

const Schema = yup.object().shape({
  sns_youtube_bid_price: yup.string().trim().nullable(true),
  sns_fb_bid_price: yup.string().trim().nullable(true),
  sns_twitter_bid_price: yup.string().trim().nullable(true),
  sns_tiktok_bid_price: yup.string().trim().nullable(true),
});
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSubmit: React.FC<DialogProps> = ({
  open,
  dataProject,
  handleClose,
  revalidate,
  edit,
}) => {
  const { t } = useTranslation();
  const dataAuth = useContext(AuthContext);
  const [checkCapCha, setCheckCapCha] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(true);
  const [checkYoutube, setCheckYoutube] = useState<boolean>(
    dataProject.my_proposal &&
      (dataProject.my_proposal.sns_youtube_bid_price ||
        dataProject.my_proposal.sns_youtube_bid_price !== 0)
      ? true
      : false
  );
  const [checkFacebook, setCheckFacebook] = useState<boolean>(
    dataProject.my_proposal &&
      (dataProject.my_proposal.sns_fb_bid_price ||
        dataProject.my_proposal.sns_fb_bid_price !== 0)
      ? true
      : false
  );
  const [checkTiktok, setCheckTiktok] = useState<boolean>(
    dataProject.my_proposal &&
      (dataProject.my_proposal.sns_tiktok_bid_price ||
        dataProject.my_proposal.sns_tiktok_bid_price !== 0)
      ? true
      : false
  );
  const [checkTwitter, setCheckTwitter] = useState<boolean>(
    dataProject.my_proposal &&
      (dataProject.my_proposal.sns_twitter_bid_price ||
        dataProject.my_proposal.sns_twitter_bid_price !== 0)
      ? true
      : false
  );
  const handleCheckYoutube = () => {
    setCheckYoutube(!checkYoutube);
  };
  const handleCheckFacebook = () => {
    setCheckFacebook(!checkFacebook);
  };
  const handleCheckTiktok = () => {
    setCheckTiktok(!checkTiktok);
  };
  const handleCheckTwitter = () => {
    setCheckTwitter(!checkTwitter);
  };

  const handleCheckAll = () => {
    setCheckYoutube(true);
    setCheckFacebook(true);
    setCheckTiktok(true);
    setCheckTwitter(true);
  };

  const captchaRef = useRef<ReCAPTCHA>(null);
  const methods = useForm<Proposal>({
    resolver: yupResolver(Schema),
    defaultValues: {
      project_id: dataProject.id,
      affiliator_id: dataAuth.admin.id,
      sns_youtube_bid_price:
        dataProject.my_proposal &&
        (dataProject.my_proposal.sns_youtube_bid_price ||
          dataProject.my_proposal.sns_youtube_bid_price === 0)
          ? dataProject.my_proposal.sns_youtube_bid_price
          : null,
      sns_fb_bid_price:
        dataProject.my_proposal &&
        (dataProject.my_proposal.sns_fb_bid_price ||
          dataProject.my_proposal.sns_fb_bid_price === 0)
          ? dataProject.my_proposal.sns_fb_bid_price
          : null,
      sns_tiktok_bid_price:
        dataProject.my_proposal &&
        (dataProject.my_proposal.sns_tiktok_bid_price ||
          dataProject.my_proposal.sns_tiktok_bid_price === 0)
          ? dataProject.my_proposal.sns_tiktok_bid_price
          : null,
      sns_twitter_bid_price:
        dataProject.my_proposal &&
        (dataProject.my_proposal.sns_twitter_bid_price ||
          dataProject.my_proposal.sns_twitter_bid_price === 0)
          ? dataProject.my_proposal.sns_twitter_bid_price
          : null,
      introduction:
        dataProject.my_proposal && dataProject.my_proposal.introduction
          ? dataProject.my_proposal.introduction
          : "",
    },
  });

  const onChangeCapCha = () => {
    setCheckCapCha(true);
    setSent(true);
  };
  const api = useAPI();
  const search = window.location.search.split("=");
  const onSubmit = async (data: Proposal) => {
    if (
      (data.sns_youtube_bid_price === null ||
        Number(data.sns_youtube_bid_price) === 0) &&
      (data.sns_fb_bid_price === null || Number(data.sns_fb_bid_price) === 0) &&
      (data.sns_tiktok_bid_price === null ||
        Number(data.sns_tiktok_bid_price) === 0) &&
      (data.sns_twitter_bid_price === null ||
        Number(data.sns_twitter_bid_price) === 0)
    ) {
      toast.error(t("text_form.required_sns"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    if (!checkYoutube && !checkFacebook && !checkTiktok && !checkTwitter) {
      toast.error(t("text_form.required_sns_check"), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    let newPriceYoutube =
      data.sns_youtube_bid_price &&
      ((data.sns_youtube_bid_price as unknown) as string).replace(/,/g, "");
    let newPriceFb =
      data.sns_fb_bid_price &&
      ((data.sns_fb_bid_price as unknown) as string).replace(/,/g, "");
    let newPriceTiktok =
      data.sns_tiktok_bid_price &&
      ((data.sns_tiktok_bid_price as unknown) as string).replace(/,/g, "");
    let newPriceTwitter =
      data.sns_twitter_bid_price &&
      ((data.sns_twitter_bid_price as unknown) as string).replace(/,/g, "");

    const newData: Proposal = {
      ...data,
      sns_youtube_bid_price:
        data.sns_youtube_bid_price && newPriceYoutube && checkYoutube
          ? parseFloat(newPriceYoutube)
          : 0,
      sns_fb_bid_price:
        data.sns_fb_bid_price && newPriceFb && checkFacebook
          ? parseFloat(newPriceFb)
          : 0,
      sns_tiktok_bid_price:
        data.sns_tiktok_bid_price && newPriceTiktok && checkTiktok
          ? parseFloat(newPriceTiktok)
          : 0,
      sns_twitter_bid_price:
        data.sns_twitter_bid_price && newPriceTwitter && checkTwitter
          ? parseFloat(newPriceTwitter)
          : 0,
    };

    setCheckCapCha(false);
    setSent(checkCapCha);
    captchaRef?.current?.reset();

    if (edit) {
      if (
        checkCapCha &&
        ((data.sns_youtube_bid_price &&
          Number(data.sns_youtube_bid_price) !== 0 &&
          checkYoutube) ||
          (data.sns_fb_bid_price &&
            Number(data.sns_fb_bid_price) !== 0 &&
            checkFacebook) ||
          (data.sns_tiktok_bid_price &&
            Number(data.sns_tiktok_bid_price) !== 0 &&
            checkTiktok) ||
          (data.sns_twitter_bid_price &&
            Number(data.sns_twitter_bid_price) !== 0 &&
            checkTwitter))
      ) {
        const res = await api.fetcher(
          "put",
          `user/proposals/${dataProject.my_proposal.id}`,
          {
            ...newData,
            approval_status: 1,
            referer_code: search.length > 0 ? search[1] : null,
          }
        );

        if (res?.success === true) {
          toast.success(t("response.success"), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          revalidate();
          handleClose();
        } else {
          toast.error("Error", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    } else {
      if (
        checkCapCha &&
        ((data.sns_youtube_bid_price &&
          Number(data.sns_youtube_bid_price) !== 0 &&
          checkYoutube) ||
          (data.sns_fb_bid_price &&
            Number(data.sns_fb_bid_price) !== 0 &&
            checkFacebook) ||
          (data.sns_tiktok_bid_price &&
            Number(data.sns_tiktok_bid_price) !== 0 &&
            checkTiktok) ||
          (data.sns_twitter_bid_price &&
            Number(data.sns_twitter_bid_price) !== 0 &&
            checkTwitter))
      ) {
        const res = await api.fetcher("post", "user/proposals", {
          ...newData,
          referer_code: search.length > 0 ? search[1] : null,
        });
        if (res?.success === true) {
          toast.success(t("response.success"), {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          revalidate();
          handleClose();
        } else {
          toast.error("Error", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      BackdropProps={{
        sx: {
          backgroundColor: "#141414cc",
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          background: "#1F2039",
          margin: "0 0px",
          maxHeight: "calc(100% - 16px)",
          borderRadius: "5px",
          width: "100%",
          maxWidth: "650px",
        },
      }}
    >
      <Box>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogTitle
              sx={{
                typography: "h5",
                fontWeight: 700,
                color: "white",
              }}
            >
              {t("proposal.proposal")}
            </DialogTitle>
            <DialogContent>
              <FormProposal
                dataSNS={dataProject}
                handleCheckYoutube={handleCheckYoutube}
                checkYoutube={checkYoutube}
                checkFacebook={checkFacebook}
                handleCheckFacebook={handleCheckFacebook}
                checkTiktok={checkTiktok}
                handleCheckTiktok={handleCheckTiktok}
                checkTwitter={checkTwitter}
                handleCheckTwitter={handleCheckTwitter}
                handleCheckAll={handleCheckAll}
              />
            </DialogContent>
            <DialogActions sx={{ padding: "0px 20px 24px 20px" }}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={12}>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey="6LfmjqYgAAAAAH4d2eYuD1pdoFxTf7Rj15AFaY5N"
                    onChange={onChangeCapCha}
                  />
                  {!sent ? (
                    <Typography
                      sx={{ fontSize: "12px" }}
                      color="#d32f2f"
                      pl={2}
                    >
                      Please checkbox again.
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs="auto">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="300px"
                  >
                    <ButtonOk type="submit">
                      {t("project_detail.Submit")}
                    </ButtonOk>
                    <ButtonCancel
                      onClick={() => {
                        handleClose();
                        captchaRef?.current?.reset();
                        methods.reset({
                          project_id: dataProject.id,
                          affiliator_id: dataAuth.admin.id,
                          sns_youtube_bid_price:
                            dataProject.my_proposal &&
                            (dataProject.my_proposal.sns_youtube_bid_price ||
                              dataProject.my_proposal.sns_youtube_bid_price ===
                                0)
                              ? dataProject.my_proposal.sns_youtube_bid_price
                              : "",
                          sns_fb_bid_price:
                            dataProject.my_proposal &&
                            (dataProject.my_proposal.sns_fb_bid_price ||
                              dataProject.my_proposal.sns_fb_bid_price === 0)
                              ? dataProject.my_proposal.sns_fb_bid_price
                              : "",
                          sns_tiktok_bid_price:
                            dataProject.my_proposal &&
                            (dataProject.my_proposal.sns_tiktok_bid_price ||
                              dataProject.my_proposal.sns_tiktok_bid_price ===
                                0)
                              ? dataProject.my_proposal.sns_tiktok_bid_price
                              : "",
                          sns_twitter_bid_price:
                            dataProject.my_proposal &&
                            (dataProject.my_proposal.sns_twitter_bid_price ||
                              dataProject.my_proposal.sns_twitter_bid_price ===
                                0)
                              ? dataProject.my_proposal.sns_twitter_bid_price
                              : "",
                          introduction:
                            dataProject.my_proposal &&
                            dataProject.my_proposal.introduction
                              ? dataProject.my_proposal.introduction
                              : "",
                        });
                      }}
                    >
                      {t("proposal.cancel")}
                    </ButtonCancel>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </Box>
    </Dialog>
  );
};

export default DialogSubmit;

export const ButtonCancel = styled(Button)(() => ({
  background: "rgba(255, 255, 255, 0.1)",
  border: "1px solid #00F0FF",
  borderRadius: "5px",
  height: "40px",
  minWidth: "140px",
  fontWeight: 700,
  color: "white",
  textTransform: "none",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.1)",
  },
}));

export const ButtonOk = styled(Button)(() => ({
  background: "#56CCF2",
  borderRadius: "5px",
  height: "40px",
  minWidth: "140px",
  fontWeight: 700,
  color: "white",
  textTransform: "none",
  "&:hover": {
    background: "#56CCF2",
  },
}));
