import { isMobile } from "common/constants";
import React from "react";
import Register from "./Register";
import RegisterMobile from "./RegisterMobile";

export default function UpdateInfo() {
  return isMobile.any() && window.innerWidth < 1440 ? (
    <RegisterMobile />
  ) : (
    <Register />
  );
}
