import { Button, makeStyles } from "@material-ui/core";
import { Dialog, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  handleChangeOpen: (value: boolean) => void;
  handleChangeCheckBox: (value: boolean) => void;
}

export const TermDialog: React.FC<Props> = ({
  open,
  handleChangeOpen,
  handleChangeCheckBox,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <Dialog open={open} maxWidth="lg">
      <div
        style={{
          padding: 20,
          height: "calc(100vh - 10vh)",
          overflow: "hidden",
        }}
      >
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 800,
            color: "black",
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          {t("term_of_use.title")}
        </Typography>
        <div
          style={{
            overflow: "auto",
            height: "calc(100vh - 10vh - 240px)",
            border: "#f3f3f3 solid 5px",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Typography className={classes.description}>
            {t("term_of_use.content")}
          </Typography>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_1")}
            </Typography>
            <Typography className={classes.term_info}>
              {t("term_of_use.article_1_info")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_1_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_1_sub_2")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>3.</Typography>
              <Typography>{t("term_of_use.article_1_sub_3")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_2")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_2_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_2_sub_2")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_3")}
            </Typography>
            <Typography className={classes.term_info}>
              {t("term_of_use.article_3_info")}
            </Typography>
            <Typography
              className={classes.term_header}
              style={{ marginTop: 20, fontWeight: 800 }}
            >
              {t("term_of_use.article_3_1")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_3_1_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_3_1_sub_1")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_4")}
            </Typography>
            <Typography className={classes.term_info}>
              {t("term_of_use.article_4_info")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_4_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_4_sub_2")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>3.</Typography>
              <Typography>{t("term_of_use.article_4_sub_3")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>4.</Typography>
              <Typography>{t("term_of_use.article_4_sub_4")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>5.</Typography>
              <Typography>{t("term_of_use.article_4_sub_5")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_5")}
            </Typography>
            <Typography className={classes.term_info}>
              {t("term_of_use.article_5_info_1")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_5_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_5_sub_2")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>3.</Typography>
              <Typography>{t("term_of_use.article_5_sub_3")}</Typography>
            </div>
            <Typography className={classes.term_info}>
              {t("term_of_use.article_5_info_2")}
            </Typography>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_6")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_6_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_6_sub_2")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_7")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_7_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_7_sub_2")}</Typography>
            </div>
          </div>

          <div className={classes.block_temp}>
            <Typography
              className={classes.term_header}
              style={{ fontWeight: 800 }}
            >
              {t("term_of_use.article_8")}
            </Typography>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>1.</Typography>
              <Typography>{t("term_of_use.article_8_sub_1")}</Typography>
            </div>
            <div className={classes.term_condition_sub}>
              <Typography style={{ marginRight: 10 }}>2.</Typography>
              <Typography>{t("term_of_use.article_8_sub_2")}</Typography>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "10px",
            margin: "10px auto",
            borderRadius: "8px",
            border: "#56CCF2 solid 1px",
            backgroundColor: "#56CCF2",
            color: "#000000",
            width: "100px",
            textAlign: "center",
            fontWeight: 800,
            cursor: "pointer",
          }}
          onClick={() => {
            handleChangeCheckBox(true);
            handleChangeOpen(false);
          }}
        >
          {t("text_form.accept")}
        </div>
      </div>
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: 800,
    color: "black",
    textAlign: "center",
    marginBottom: 20,
  },
  description: {
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
  },

  block_temp: {
    margin: "20px 0px",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "black",
  },

  term_header: {
    fontWeight: 800,
  },

  term_info: {
    marginLeft: "10px",
  },

  term_condition_sub: {
    display: "flex",
    marginLeft: "10px",
  },
}));
