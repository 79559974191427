import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAPI } from "api/api";
import { makeStyles } from "@material-ui/styles";

interface Props {
  endpoint: string;
  queryField: string;
  valueField: string;
  labelField: string;
  value: any;
  handleChange: (value: any) => void;
  label: string;
  error?: string;
}

export const AutocompleteContainer: React.FC<Props> = ({
  endpoint,
  queryField,
  value,
  valueField,
  labelField,
  handleChange,
  label,
  error,
}) => {
  const [query, setQuery] = useState<any>({ per_page: 200 });
  const api = useAPI();

  useEffect(() => {
    if (endpoint) {
      setTimeout(() => {
        api.fetcher("get", endpoint, JSON.stringify(query));
      }, 200);
    }
  }, [query, endpoint]);

  const options = endpoint ? api?.data?.data || [] : [];

  const classes = useStyle();

  return (
    <Autocomplete
      className={classes.container}
      size={"small"}
      fullWidth
      includeInputInList={true}
      openOnFocus={true}
      loading={api?.loading}
      loadingText="Loading..."
      noOptionsText="No Data"
      value={value || null}
      options={options}
      getOptionLabel={(option: any) =>
        option?.[labelField ? labelField : queryField]
      }
      renderOption={(option: any, state) => {
        return (
          <div style={{ padding: 5 }}>
            {option?.[labelField ? labelField : queryField]}
          </div>
        );
      }}
      onChange={(event, value, reason) => {
        handleChange(value);
      }}
      onClose={() => {
        setQuery({ [queryField]: null, per_page: 200 });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          onChange={(event) =>
            setQuery({ [queryField]: event.target.value, per_page: 200 })
          }
          error={!!error}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {api?.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            classes: { input: classes.input },
            className: classes.lineColor,
            style: { height: "50px", color: "white" },
          }}
        />
      )}
    />
  );
};

const useStyle = makeStyles(() => ({
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "white",
    },
  },
  lineColor: {
    color: "white",
  },
  labelText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "white",
    marginBottom: "2px",
    marginTop: "24px",
  },
  textFieldContainer: {
    backgroundColor: "#0000004d",
    borderRadius: "8px",
    border: "1px solid #bdbdbd4d",
    color: "white",
    marginBottom: "10px",
  },
  container: {
    backgroundColor: "#0000004d",
    color: "white",
    borderRadius: "8px",
    height: "50px",
    border: "1px solid #bdbdbd4d",
  },
}));
