import { Avatar, Badge } from "@mui/material";
import React, { useContext } from "react";

import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { MessageContext } from "containers/MessageProvider";

interface Props {
  newNotification: boolean;
  open: boolean;
}

const NotificationBadge: React.FC<Props> = ({ newNotification, open }) => {
  const { newNotiNumber } = useContext(MessageContext);
  return (
    <>
      <Badge
        color="error"
        overlap="circular"
        variant="dot"
        invisible={
          newNotification ||
          open ||
          (newNotiNumber && newNotiNumber > 0 ? false : true)
        }
      >
        <Avatar
          sx={{
            backgroundColor: "#1F2039",
            width: "50px",
            height: "50px",
          }}
        >
          <NotificationsActiveIcon />
        </Avatar>
      </Badge>
    </>
  );
};

export default NotificationBadge;
