import { valueAuth } from "common/Type/authType";
import { firebaseDataMessage, MessageType } from "common/Type/messageType";
import React, { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
// import Cookies from 'js-cookie';
import { useAPI } from "../api/api";
import Loading from "../components/Loading";
import { getFromLocalStorage } from "./Admin/Chat/components/RoomList";
//-------------------------------------

const defaultValue: valueAuth = {
  conversationSelected: 0,
  updateConversation: (e) => {},
  notiMessage: false,
  admin: null,
  firebaseData: null,
  clearDataFirebase: () => {},
  updateDataFirebase: () => {},
  updateNotiMessage: (e) => {},
  updateAdminToken: () => {},
  updateAdmin: () => {},
  clear: () => {},
  revalidate: () => {},
};

export const AuthContext = createContext<valueAuth>(defaultValue);

/**
 * Restore auth user from access_token is persisted in localStorage.
 *
 * TODO: handle refresh token in here.
 */
const AuthProvider = React.memo((props) => {
  const [admin, setAdmin] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [notiMessage, setNotiMessage] = useState(false);
  const [conversationSelected, setConversationSelected] = useState<number>(0);
  const [firebaseData, setFirebaseData] = useState<any>(null);

  const api = useAPI();

  const updateAdminToken = async (token: string, remember: any) => {
    if (remember) {
      localStorage.setItem("admin_token", token);
    } else {
      // Cookies.set('admin_token', token);
      localStorage.setItem("admin_token", token);
    }
  };

  const updateAdmin = async (data: any) => {
    setAdmin(data);
  };

  const updateDataFirebase = (data: any) => {
    if (data) {
      setFirebaseData({
        id: +data.room_id,
        content: data.content,
        created_at: data.created_at,
        from_afiliator_flag: +data.from_afiliator_flag,
        from_po_flag: +data.from_po_flag,
        project_owner_id: +data.po_id,
      });
    } else setFirebaseData(null);
  };

  const clearDataFirebase = () => {
    setFirebaseData(null);
  };

  const clear = async () => {
    localStorage.removeItem("admin_token");
    // Cookies.remove('admin_token');
    setAdmin(null);
  };

  const updateConversation = (e: number) => {
    setConversationSelected(e);
  };

  const updateNotiMessage = (e: boolean) => {
    setNotiMessage(e);
  };

  const revalidate = async () => {
    getUserInfo();
  };

  const value: valueAuth = {
    conversationSelected,
    notiMessage,
    admin,
    firebaseData,
    clearDataFirebase,
    updateDataFirebase,
    updateConversation,
    updateNotiMessage,
    updateAdminToken,
    updateAdmin,
    clear,
    revalidate,
  };

  const getUserInfo = async () => {
    const token = localStorage.getItem("admin_token");
    if (token) {
      try {
        const res = await api.fetcher("get", "/user/me");
        setAdmin(res);
      } catch (error) {
        setAdmin(null);
        localStorage.removeItem("admin_token");
      }
    } else {
      setAdmin(null);
    }
    setLoaded(true);
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return <Loading />;
  }

  return <AuthContext.Provider value={value as valueAuth} {...props} />;
});

export default AuthProvider;
