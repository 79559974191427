import { Dialog, makeStyles } from "@material-ui/core";
import {
  Popover,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { AuthContext } from "containers/AuthProvider";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import defaultAvatar from "../../assets/Avatar/avatar1.png";
import { ChangeInfo } from "./ChangeInfo";

export const NoMaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const PersonInfo: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const theme = useTheme();

  const { admin, clear } = useContext(AuthContext);

  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const logout = () => {
    clear();
    history.push("/");
  };

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const convertStringAddress = (value: string) => {
    return (
      value.slice(0, 5) +
      "........." +
      value.slice(value.length - 4, value.length)
    );
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <NoMaxWidthTooltip
        title={
          <Typography
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: 700,
              wordBreak: "break-word",
            }}
          >
            {admin?.wallet_address}
          </Typography>
        }
      >
        <div
          style={
            admin?.wallet_address
              ? {
                  borderRadius: "25px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "8px 16px 8px 0px",
                  height: theme.spacing(5),
                  width: "200px",
                  backgroundColor: "white",
                  cursor: "pointer",
                }
              : {
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "8px 16px 8px 16px",
                  height: theme.spacing(5),
                  cursor: "pointer",
                }
          }
          onClick={(e) => handlePopoverOpen(e)}
        >
          <img
            src={admin?.avatar_url ? admin?.avatar_url : defaultAvatar}
            height="40px"
            width="40px"
            alt=""
            style={{
              marginRight: "10px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
          <Typography
            style={{ color: "black", fontSize: "16px", fontWeight: 700 }}
          >
            {admin?.wallet_address
              ? convertStringAddress(admin?.wallet_address)
              : null}
          </Typography>
        </div>
      </NoMaxWidthTooltip>
      <Popover
        open={open}
        id="popover"
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { background: "transparent" },
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          id="stack"
          sx={{ backgroundColor: "#00000080 !important", padding: "10px" }}
        >
          {(admin?.country_id || admin?.wallet_address) && (
            <div
              className={classes.containerMenu}
              style={{ borderBottom: "#ffffff4d solid 1px" }}
              onClick={() => handleOpenDialog()}
            >
              <Typography className={classes.textMenu}>
                {t("header.Change_Information")}
              </Typography>
            </div>
          )}
          <div className={classes.containerMenu} onClick={() => logout()}>
            <Typography className={classes.textMenu}>
              {t("header.Logout")}
            </Typography>
          </div>
        </Stack>

        <ChangeInfo open={openDialog} handleCloseDialog={handleCloseDialog} />
      </Popover>
    </Stack>
  );
};

const useStyles = makeStyles(() => ({
  containerMenu: {
    minWidth: "200px",
    height: "50px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  textMenu: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 800,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
}));
