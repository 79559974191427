import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  styled,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Proposal } from "common/Type/proposalType";
import { ResponseProject } from "common/Type/responseProject";
import { CustomTextField } from "containers/PCView/WhiteListForm/Component/Information";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import IconYoutube from "assets/icons8-youtube.svg";
import IconFb from "assets/icons8-facebook.svg";
import IconTiktok from "assets/icons8-tiktok.svg";
import IconTwitter from "assets/icons8-twitter.svg";
import NumberFormat, { InputAttributes } from "react-number-format";
import { useTranslation } from "react-i18next";
import { ButtonOk } from "./DialogSubmit";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
export const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  const MAX_LIMIT = 100000000;
  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue ? floatValue < MAX_LIMIT : true;
      }}
      thousandSeparator
      isNumericString
      style={{
        fontWeight: 500,
        marginRight: "10px",
      }}
    />
  );
});
interface ProposalContentProps {
  dataSNS: ResponseProject;
  checkYoutube: boolean;
  checkFacebook: boolean;
  checkTiktok: boolean;
  checkTwitter: boolean;
  handleCheckYoutube: () => void;
  handleCheckFacebook: () => void;
  handleCheckTiktok: () => void;
  handleCheckTwitter: () => void;
  handleCheckAll: () => void;
}
const FormProposal: React.FC<ProposalContentProps> = ({
  dataSNS,
  handleCheckYoutube,
  checkYoutube,
  checkFacebook,
  checkTiktok,
  checkTwitter,
  handleCheckFacebook,
  handleCheckTiktok,
  handleCheckTwitter,
  handleCheckAll,
}) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<Proposal>();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 450px)");
  const [suggest, setSuggest] = useState<boolean>(false);

  useEffect(() => {
    if (suggest === true) {
      setValue("sns_youtube_bid_price", dataSNS.sns_youtube_price || null);
      setValue("sns_fb_bid_price", dataSNS.sns_fb_price || null);
      setValue("sns_tiktok_bid_price", dataSNS.sns_tiktok_price || null);
      setValue("sns_twitter_bid_price", dataSNS.sns_twitter_price || null);
      setSuggest(false);
    }
  }, [
    suggest,
    setSuggest,
    setValue,
    dataSNS.sns_youtube_price,
    dataSNS.sns_fb_price,
    dataSNS.sns_tiktok_price,
    dataSNS.sns_twitter_price,
  ]);

  const ytb = useWatch({
    control,
    name: "sns_youtube_bid_price",
  });
  const fb = useWatch({
    control,
    name: "sns_fb_bid_price",
  });
  const tt = useWatch({
    control,
    name: "sns_tiktok_bid_price",
  });
  const tw = useWatch({
    control,
    name: "sns_twitter_bid_price",
  });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            lineHeight: "22px",
            color: "white",
          }}
        >
          {t("proposal.price")}
        </Typography>

        <ButtonOk
          onClick={() => {
            setSuggest(true);
            handleCheckAll();
          }}
        >
          {t("text_form.Suggest_price")}
        </ButtonOk>
      </Stack>
      {/* <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb="12px"
      >
        <Typography
          variant="body2"
          sx={{
            color: "white",
          }}
        >
          {t("text_form.note_suggest")}
        </Typography>
      </Stack> */}
      <Stack sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {dataSNS.sns_youtube_price ? (
            <Grid item xs={isMobile ? 12 : 6} sm={6}>
              <Box position="relative">
                <NewTextField
                  {...register("sns_youtube_bid_price")}
                  id="formatted-numberformat-input"
                  name="sns_youtube_bid_price"
                  variant="outlined"
                  fullWidth
                  disabled={!checkYoutube}
                  value={ytb}
                  placeholder={t("text_form.Your_bid_price")}
                  error={!!errors.sns_youtube_bid_price}
                  helperText={
                    errors.sns_youtube_bid_price
                      ? (errors.sns_youtube_bid_price.message as string)
                      : " "
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={IconYoutube} alt="icon" />
                      </InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: checkYoutube ? "white" : "#56CCF2",
                    opacity: checkYoutube ? 1 : 0.5,
                    fontWeight: 700,
                    position: "absolute",
                    top: "16px",
                    left: "67px",
                  }}
                >
                  $
                </Typography>
                <Checkbox
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "0",
                    color: "white",
                    "&.Mui-checked": {
                      color: "#00F0FF",
                    },
                  }}
                  checked={checkYoutube}
                  onChange={handleCheckYoutube}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
          ) : null}
          {dataSNS.sns_fb_price ? (
            <Grid item xs={isMobile ? 12 : 6} sm={6}>
              <Box position="relative">
                <NewTextField
                  {...register("sns_fb_bid_price")}
                  id="formatted-numberformat-input"
                  name="sns_fb_bid_price"
                  variant="outlined"
                  fullWidth
                  disabled={!checkFacebook}
                  value={fb}
                  placeholder={t("text_form.Your_bid_price")}
                  error={!!errors.sns_fb_bid_price}
                  helperText={
                    errors.sns_fb_bid_price
                      ? (errors.sns_fb_bid_price.message as string)
                      : " "
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <img
                          src={IconFb}
                          alt="icon"
                          width="40px"
                          height="40px"
                          style={{
                            marginRight: "8px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: checkFacebook ? "white" : "#56CCF2",
                    opacity: checkFacebook ? 1 : 0.5,
                    fontWeight: 700,
                    position: "absolute",
                    top: "16px",
                    left: "67px",
                  }}
                >
                  $
                </Typography>
                <Checkbox
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "0",
                    color: "white",
                    "&.Mui-checked": {
                      color: "#00F0FF",
                    },
                  }}
                  checked={checkFacebook}
                  onChange={handleCheckFacebook}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
          ) : null}
          {dataSNS.sns_tiktok_price ? (
            <Grid item xs={isMobile ? 12 : 6} sm={6}>
              <Box position="relative">
                <NewTextField
                  {...register("sns_tiktok_bid_price")}
                  id="formatted-numberformat-input"
                  name="sns_tiktok_bid_price"
                  variant="outlined"
                  fullWidth
                  disabled={!checkTiktok}
                  value={tt}
                  placeholder={t("text_form.Your_bid_price")}
                  error={!!errors.sns_tiktok_bid_price}
                  helperText={
                    errors.sns_tiktok_bid_price
                      ? (errors.sns_tiktok_bid_price.message as string)
                      : " "
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={IconTiktok} alt="icon" />
                      </InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: checkTiktok ? "white" : "#56CCF2",
                    opacity: checkTiktok ? 1 : 0.5,
                    fontWeight: 700,
                    position: "absolute",
                    top: "16px",
                    left: "67px",
                  }}
                >
                  $
                </Typography>
                <Checkbox
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "0",
                    color: "white",
                    "&.Mui-checked": {
                      color: "#00F0FF",
                    },
                  }}
                  checked={checkTiktok}
                  onChange={handleCheckTiktok}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
          ) : null}
          {dataSNS.sns_twitter_price ? (
            <Grid item xs={isMobile ? 12 : 6} sm={6}>
              <Box position="relative">
                <NewTextField
                  {...register("sns_twitter_bid_price")}
                  id="formatted-numberformat-input"
                  name="sns_twitter_bid_price"
                  variant="outlined"
                  fullWidth
                  disabled={!checkTwitter}
                  value={tw}
                  placeholder={t("text_form.Your_bid_price")}
                  error={!!errors.sns_twitter_bid_price}
                  helperText={
                    errors.sns_twitter_bid_price
                      ? (errors.sns_twitter_bid_price.message as string)
                      : " "
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={IconTwitter} alt="icon" />
                      </InputAdornment>
                    ),
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: checkTwitter ? "white" : "#56CCF2",
                    opacity: checkTwitter ? 1 : 0.5,
                    fontWeight: 700,
                    position: "absolute",
                    top: "16px",
                    left: "67px",
                  }}
                >
                  $
                </Typography>
                <Checkbox
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "0",
                    color: "white",
                    "&.Mui-checked": {
                      color: "#00F0FF",
                    },
                  }}
                  checked={checkTwitter}
                  onChange={handleCheckTwitter}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Typography
          variant="body2"
          sx={{
            color: "white",
            marginBottom: "16px",
          }}
        >
          {t("project_detail.note_price") +
            ": " +
            (dataSNS?.payment_token_id
              ? dataSNS?.payment_token?.symbol
              : "USDT(BSC)")}
        </Typography>
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              lineHeight: "22px",
              color: "white",
              marginBottom: "16px",
            }}
          >
            {t("proposal.Introduction")}
          </Typography>
          <TextareaAutosize
            {...register("introduction")}
            aria-label="empty textarea"
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "74px",
              padding: "14px",
              background: "#1F2039",
              color: "white",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "20px",
              borderColor: "#D9D5D5",
              borderRadius: "5px",
            }}
          />
        </Box>
      </Stack>
    </>
  );
};
export default FormProposal;
export const NewTextField = styled(CustomTextField)(() => ({
  "& .MuiInputBase-root": {
    "& .MuiInputBase-input": {
      padding: "0 16px 0 8px",
    },
  },
  "& input.Mui-disabled": {
    color: "white",
    WebkitTextFillColor: "#56CCF2",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    opacity: "0.7",
    border: "1px solid #56CCF2",
  },

  "& .MuiInputLabel-root": {
    color: "white",
    fontWeight: 700,
    "&.Mui-focused fieldset": {
      color: "white",
    },
  },
  "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
    WebkitBoxShadow: "0 0 0 30px #1F2039 inset !important",
    WebkitTextFillColor: "white!important",
  },
}));
