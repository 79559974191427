import Menu from "@mui/material/Menu";
import React, { useEffect, useMemo, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAPI, useFetch } from "api/api";
import { NotificationType } from "common/Type/notificationType";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import CloseIcon from "@material-ui/icons/Close";
import { NOTIFICATION_TYPE, WORK_STATUS } from "common/constants";
interface MenuNotificationProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  revalidateNewNoti: () => void;
  dataNewNoti: NotificationType[];
}

interface ParamNotification {
  per_page?: number;
  page?: number;
}

const MenuNotification: React.FC<MenuNotificationProps> = ({
  anchorEl,
  open,
  handleClose,
  revalidateNewNoti,
  dataNewNoti,
}) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const initialParams: ParamNotification = {
    per_page: 9,
    page: 1,
  };
  const [params, setParams] = useState<ParamNotification>(initialParams);
  const _params: ParamNotification = useMemo(() => params, [params]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [dataNoti, setDataNoti] = useState<NotificationType[]>();

  const { data: dataListNoti, loading, revalidate } = useFetch(
    open ? ["get", "/user/notifications", JSON.stringify(_params)] : null,
    {
      onSuccess: (res: any) => {
        const value: NotificationType[] = res.data;
        setDataNoti((prev) => [...(prev || []), ...value]);
        if (res.current_page < res.last_page)
          setCurrentPage(res.current_page as number);
      },
    }
  );

  useEffect(() => {
    if (inView) {
      let dataParams = { ...params };
      dataParams.page = currentPage + 1;
      setParams(dataParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, inView, params]);

  const api = useAPI();
  const history = useHistory();
  const handleClickNotification = async (
    id: number,
    project_id: number,
    type: number
  ) => {
    if (type === 4) {
      try {
        handleClose();
        history.push(`/myPerformance`);
        revalidateNewNoti();
        setParams(initialParams);
        setDataNoti([]);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await api.fetcher("post", `/user/seen`, { notification_id: id });
        history.push(`/project-snspromotion/${project_id}`);
        handleClose();
        revalidateNewNoti();
        setParams(initialParams);
        setDataNoti([]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (open && dataNoti?.length === 0) revalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isMobile = useMediaQuery("(max-width: 450px)");

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => {
          handleClose();
          setParams(initialParams);
          setDataNoti([]);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: isMobile ? "none" : "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiMenu-list": {
              paddingTop: "16px",
              paddingBottom: "0px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{}}
      >
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: "calc(100vh - 250px)", sm: "430px" },
            width: "auto",
            minWidth: isMobile ? "275px" : "300px",
            maxWidth: isMobile ? "375px" : "450px",
            overflow: "auto",
            overflowX: "hidden",
            "::-webkit-scrollbar-track": {
              background: "rgba(143, 207, 177, 0.1)",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#D9D9D9",
            },
          }}
        >
          {!loading && (
            <>
              <Typography variant="body1" fontWeight={700} textAlign="center">
                {t("notification.title")}
              </Typography>

              <Box sx={{ position: "absolute", top: 0, right: "4px" }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    setParams(initialParams);
                    setDataNoti([]);
                  }}
                >
                  <CloseIcon style={{ color: "black" }} />
                </div>
              </Box>
            </>
          )}
          {dataNoti?.map((item: NotificationType, index: number) => (
            <MenuItem
              key={item.id}
              onClick={() =>
                handleClickNotification(item.id, item.project_id, item.type)
              }
              sx={{
                width: "auto",
                minWidth: isMobile ? "275px" : "300px",
                maxWidth: isMobile ? "375px" : "450px",
                padding: "8px 16px",
                borderBottom:
                  index < dataNoti.length - 1 ? "1px solid #D9D9D9" : "none",
              }}
            >
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
                gap={"4px"}
              >
                <Grid item xs={item.status !== 1 ? 12 : 11.25}>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      {item.project && item.project.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "black",
                        whiteSpace: "pre-wrap",
                        width: "auto",
                        maxWidth: isMobile ? "375px" : "400px",
                        wordWrap: "break-word",
                      }}
                    >
                      {item.type === NOTIFICATION_TYPE.WORK &&
                      item.work_status === WORK_STATUS.DONE
                        ? t("notification.proposal")
                        : item.type === NOTIFICATION_TYPE.WORK &&
                          item.work_status !== WORK_STATUS.WAIT_CONFIRM
                        ? t("notification.work")
                        : item.type === NOTIFICATION_TYPE.PROPOSAL
                        ? t("notification.work")
                        : item.type === NOTIFICATION_TYPE.REQUEST
                        ? t("notification.request")
                        : item.type === NOTIFICATION_TYPE.FAQ
                        ? t("notification.faq")
                        : null}
                    </Typography>
                  </Box>
                </Grid>
                {item.status === 1 && (
                  <Grid item xs={0.5}>
                    <FiberManualRecordIcon
                      style={{
                        width: 10,
                        height: 10,
                        marginLeft: "8px",
                        fill: "#d32f2f",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </MenuItem>
          ))}
          {dataNoti &&
            dataNoti.length > 8 &&
            dataListNoti?.total > dataNoti.length && (
              <Box textAlign="center">
                <Button ref={ref} />
              </Box>
            )}
          {loading && (
            <MenuItem
              sx={{
                width: "auto",
                minWidth: isMobile ? "275px" : "300px",
                maxWidth: isMobile ? "375px" : "450px",
              }}
            >
              <Skeleton height="40px" width="100%" />
            </MenuItem>
          )}
          {dataListNoti?.data?.length === 0 && (
            <MenuItem
              sx={{
                width: "auto",
                minWidth: isMobile ? "275px" : "300px",
                maxWidth: isMobile ? "375px" : "450px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                }}
              >
                {t("project_detail.no_data")}
              </Typography>
            </MenuItem>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default MenuNotification;
