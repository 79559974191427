import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useAPI, useFetch } from "api/api";
import { ResponseWhiteList, WhiteListData } from "common/Type/WhiteListType";
import LoadingTemplate from "components/LoadingTemplate";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomInfo } from "./Component/CustomInfo";
import { Information } from "./Component/Information";
import { map } from "lodash";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { QuestionVer2 } from "./Component/QuestionVer2";
export type Question = {
  question: string;
  answer: string;
};

export type AnswerSelection = {
  answer: string;
  selected: boolean;
};

export type ConfigQuestion = {
  type: number;
  question: string;
  answer_text: string;
  answer_selection: AnswerSelection[];
};
export interface FormProps {
  message: string;
  wallet_address: string;
  questions: Question[];
  questionsVer2: ConfigQuestion[];
}

const Schema = yup.object().shape({
  message: yup.string().required(),
  wallet_address: yup
    .string()
    .required("Please enter your wallet address")
    .matches(/^0x[a-fA-F0-9]{40}$/g, "Is not in correct format"),
  questions: yup.array().of(
    yup.object().shape({
      answer: yup.string().trim().required(),
    })
  ),
  questionsVer2: yup.array().of(
    yup.object().shape({
      answer_text: yup.string().trim().required(),
    })
  ),
});

export default function WhiteListFormPC() {
  const { t } = useTranslation();
  const history = useHistory();

  const Project_id = window.location.search
    .substr(1)
    .slice(11, window.location.search.substr(1).indexOf("&"));

  const Affilatior_id = window.location.search
    .substr(1)
    .slice(window.location.search.substr(1).indexOf("&") + 15);

  const api = useAPI();

  const { data, loading } = useFetch([
    "get",
    "user/get-white-list-config?project_id=" + Project_id,
  ]);

  const [dataProject, setDataProject] = useState<ResponseWhiteList>();
  useEffect(() => {
    if (data) {
      const value: ResponseWhiteList = data;
      setDataProject(value);
    }
  }, [data]);

  const DataFakeWhiteList = data?.config_json && JSON.parse(data?.config_json);

  const [dataForm, setDataForm] = useState<WhiteListData>({
    project_id: +Project_id,
    affiliator_id: +Affilatior_id,
    message: "",
    wallet_address: "",
    response_json: "",
  });

  const [objectQuestion, setObjectQuestion] = useState<Question[]>([]);
  const [objectQuestionVer2, setObjectQuestionVer2] = useState<
    ConfigQuestion[]
  >([]);
  const [checkCapCha, setCheckCapCha] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(true);
  const onChangeCapCha = () => {
    setCheckCapCha(true);
    setSent(true);
  };
  const methods = useForm<FormProps>({
    resolver: yupResolver(Schema),
    defaultValues: {
      message: "",
      wallet_address: "",
      questions: [...objectQuestion],
      questionsVer2: [...objectQuestionVer2],
    },
  });
  useEffect(() => {
    if (
      DataFakeWhiteList &&
      DataFakeWhiteList.length > 0 &&
      Array.isArray(DataFakeWhiteList)
    ) {
      methods.setValue(
        "questions",
        map(DataFakeWhiteList, ({ question }) => ({
          question,
          answer: "",
        }))
      );
    } else if (DataFakeWhiteList && !Array.isArray(DataFakeWhiteList)) {
      const dataConfig = DataFakeWhiteList.config;
      methods.setValue(
        "questionsVer2",
        map(dataConfig, ({ question, type, config_answers }) => ({
          question,
          type,
          answer_text: type === 3 ? "abc" : "",
          answer_selection: config_answers.map((item: any) => ({
            answer: item.answer,
            selected: false,
          })),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, methods]);

  const onSubmit = async (data: FormProps) => {
    setSent(checkCapCha);
    if (checkCapCha) {
      let sendData: WhiteListData = dataForm;
      sendData.message = data.message;
      sendData.wallet_address = data.wallet_address;
      sendData.response_json = JSON.stringify(
        Array.isArray(DataFakeWhiteList) ? data.questions : data.questionsVer2
      );
      const res = await api.fetcher(
        "post",
        "user/white-list-report-response",
        sendData
      );

      if (res?.success === true) {
        toast.success(t("response.success"), {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        history.push("/");
      } else {
        toast.error("Error", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const today = new Date();
  const endDay = new Date(`${dataProject?.whitelist_end_date}`);

  return (
    <Box
      bgcolor={
        dataProject?.background_color
          ? dataProject?.background_color
          : "#2f3b50"
      }
      height="100%"
    >
      {history.location.search.length === 0 || !Project_id || !Affilatior_id ? (
        <>
          <Box
            sx={{
              padding: { xs: "16px", sm: "28px" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              backgroundColor: "#2f3b50",
            }}
          >
            <Container
              maxWidth={false}
              sx={{
                maxWidth: "650px",
                paddingTop: "24px",
                paddingLeft: "0px!important",
                paddingRight: "0px!important",
                background: "#1F2039",
                borderRadius: "5px",
                height: "250px",
              }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <ErrorOutlineIcon
                  style={{
                    color: "rgb(239, 83, 80)",
                    width: "60px",
                    height: "60px",
                  }}
                />
                <Typography variant="h6" color="white">
                  {t("text_form.no_have")}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs="auto">
                    <Button
                      variant="contained"
                      sx={{
                        height: "48px",
                        width: "180px",
                        borderRadius: "5px",
                        background: "rgba(255, 255, 255, 0.1)",
                        border: "1px solid #56CCF2",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 0.1)",
                        },
                      }}
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      <SendButtonText>{t("text_form.home")}</SendButtonText>
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Container>
          </Box>
        </>
      ) : (
        <>
          {today >= endDay ? (
            <>
              <Box
                sx={{
                  padding: { xs: "16px", sm: "28px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                  backgroundColor: "#2f3b50",
                }}
              >
                <Container
                  maxWidth={false}
                  sx={{
                    maxWidth: "650px",
                    paddingTop: "24px",
                    paddingLeft: "0px!important",
                    paddingRight: "0px!important",
                    background: "#1F2039",
                    borderRadius: "5px",
                    height: "250px",
                  }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <ErrorOutlineIcon
                      style={{
                        color: "rgb(239, 83, 80)",
                        width: "60px",
                        height: "60px",
                      }}
                    />
                    <Typography variant="h6" color="white">
                      {t("text_form.out_date")}
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs="auto">
                        <Button
                          variant="contained"
                          sx={{
                            height: "48px",
                            width: "180px",
                            borderRadius: "5px",
                            background: "rgba(255, 255, 255, 0.1)",
                            border: "1px solid #56CCF2",
                            "&:hover": {
                              background: "rgba(255, 255, 255, 0.1)",
                            },
                          }}
                          onClick={() => {
                            history.push("/");
                          }}
                        >
                          <SendButtonText>{t("text_form.home")}</SendButtonText>
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </Container>
              </Box>
            </>
          ) : (
            <>
              <Box
                py={"60px"}
                bgcolor={
                  dataProject?.background_color
                    ? dataProject?.background_color
                    : "#2f3b50"
                }
              >
                <Container
                  maxWidth={false}
                  sx={{
                    maxWidth: "650px",
                    paddingLeft: "0px!important",
                    paddingRight: "0px!important",
                    background: "#1F2039",
                    borderRadius: "5px",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      padding: "28px",
                    }}
                  >
                    <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FormTitle>{dataProject?.project?.name}</FormTitle>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "white",
                            mb: "16px",
                            wordBreak: "break-word",
                          }}
                        >
                          {dataProject?.description}
                        </Typography>
                        <Information />
                        {dataProject?.config_json !== null &&
                        DataFakeWhiteList &&
                        Array.isArray(DataFakeWhiteList) ? (
                          <CustomInfo />
                        ) : null}
                        {dataProject?.config_json !== null &&
                        DataFakeWhiteList &&
                        !Array.isArray(DataFakeWhiteList) ? (
                          <QuestionVer2 />
                        ) : null}

                        <Grid
                          container
                          justifyContent="space-between"
                          rowSpacing={4}
                        >
                          <Grid item xs={12}>
                            <ReCAPTCHA
                              sitekey="6LfmjqYgAAAAAH4d2eYuD1pdoFxTf7Rj15AFaY5N"
                              onChange={onChangeCapCha}
                            />
                            {!sent ? (
                              <Typography
                                sx={{ fontSize: "12px" }}
                                color="#d32f2f"
                                pl={2}
                                mt="6px"
                              >
                                please checkbox again.
                              </Typography>
                            ) : null}
                          </Grid>
                          <Grid item xs="auto">
                            <Button
                              type="submit"
                              variant="contained"
                              sx={{
                                height: "56px",
                                width: "120px",
                                borderRadius: "5px",
                                background: "#56CCF2",
                                "&:hover": {
                                  background: "#56CCF2",
                                },
                              }}
                            >
                              <SendButtonText>
                                {t("text_form.Submit")}
                              </SendButtonText>
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </FormProvider>
                  </Box>
                </Container>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

const FormTitle = styled(Typography)(() => ({
  fontSize: "24px",
  lineHeight: "28px",
  fontWeight: 700,
  color: "white",
  marginBottom: "20px",
}));

const SendButtonText = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "22px",
  color: "white",
  textTransform: "none",
}));
