import { makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function TermOfUse() {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#2f3b50",
        padding: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{ padding: 20, height: "100%", width: "70%", overflow: "auto" }}
      >
        <Typography className={classes.title}>
          {t("term_of_use.title")}
        </Typography>
        <Typography className={classes.description}>
          {t("term_of_use.content")}
        </Typography>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_1")}
          </Typography>
          <Typography className={classes.term_info}>
            {t("term_of_use.article_1_info")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_1_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_1_sub_2")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>3.</Typography>
            <Typography>{t("term_of_use.article_1_sub_3")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_2")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_2_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_2_sub_2")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_3")}
          </Typography>
          <Typography className={classes.term_info}>
            {t("term_of_use.article_3_info")}
          </Typography>
          <Typography className={classes.term_header} style={{ marginTop: 20 }}>
            {t("term_of_use.article_3_1")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_3_1_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_3_1_sub_1")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_4")}
          </Typography>
          <Typography className={classes.term_info}>
            {t("term_of_use.article_4_info")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_4_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_4_sub_2")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>3.</Typography>
            <Typography>{t("term_of_use.article_4_sub_3")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>4.</Typography>
            <Typography>{t("term_of_use.article_4_sub_4")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>5.</Typography>
            <Typography>{t("term_of_use.article_4_sub_5")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_5")}
          </Typography>
          <Typography className={classes.term_info}>
            {t("term_of_use.article_5_info_1")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_5_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_5_sub_2")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>3.</Typography>
            <Typography>{t("term_of_use.article_5_sub_3")}</Typography>
          </div>
          <Typography className={classes.term_info}>
            {t("term_of_use.article_5_info_2")}
          </Typography>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_6")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_6_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_6_sub_2")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_7")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_7_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_7_sub_2")}</Typography>
          </div>
        </div>

        <div className={classes.block_temp}>
          <Typography className={classes.term_header}>
            {t("term_of_use.article_8")}
          </Typography>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>1.</Typography>
            <Typography>{t("term_of_use.article_8_sub_1")}</Typography>
          </div>
          <div className={classes.term_condition_sub}>
            <Typography style={{ marginRight: 10 }}>2.</Typography>
            <Typography>{t("term_of_use.article_8_sub_2")}</Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
}

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    fontWeight: 800,
    color: "black",
    textAlign: "center",
    marginBottom: 20,
  },
  description: {
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
  },

  block_temp: {
    margin: "20px 0px",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "black",
  },

  term_header: {
    fontWeight: 800,
  },

  term_info: {
    marginLeft: "10px",
  },

  term_condition_sub: {
    display: "flex",
    marginLeft: "10px",
  },
}));
